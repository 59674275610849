import React, { useState, useEffect } from "react";
import {
    Container,
    Card,
} from "reactstrap";

import PropTypes from "prop-types"
import PLYSIMO from "./PLYSIMO";
import TRIMA from "./TRIMA";
import MyDrawer from "./drawer";
import ReactDrawer from 'react-drawer';

// redux
import { useDispatch } from "react-redux";

// helpers
import { getMaterials, getTasks, getProducts } from "../../helpers/api_helper";

const Pressa = (props) => {
    //meta title
    document.title = "Profile | kerino - React Admin & Dashboard Template";

    const [type] = useState("ΕΣΤΙΑΣΗ");
    const [tasks, setTasks] = useState([]);
    const [sectiontasks, setsectiontasks] = useState([]);
    const [materialsList, setMaterialsList] = useState([]);
    const [filteredMaterials, setFilteredMaterials] = useState([]);
    const [search, setSearch] = useState('');
    const [open, setOpen] = useState(false);
    const [selectedReplace, setselectedReplace] = useState({});
    const [selectedReplaceindex, setselectedReplaceindex] = useState("");

    const dispatch = useDispatch();

    // Fetch and filter tasks based on type "ΕΣΤΙΑΣΗ"
    useEffect(() => {
        const fetchTasks = async () => {
            const allTasks = await getTasks();
            const filtered = filterPayload(allTasks, type);
            setTasks(allTasks);
            setsectiontasks(filtered);
        };

        fetchTasks();
    }, [type]);

    // Fetch materials on component mount
    useEffect(() => {
        getMaterials().then(res => setMaterialsList(res));
    }, []);

    // Filter materials based on search input
    useEffect(() => {
        const searchRegex = new RegExp(search, 'i');
        const filtered = search
            ? materialsList.filter((cust) => searchRegex.test(cust.name) || searchRegex.test(cust.description))
            : materialsList.filter((cust) => cust.stock > 0 && cust.usageType !== "COMPLETE-PRODUCT");
        setFilteredMaterials(filtered);
    }, [search, materialsList]);

    // Helper function to filter tasks based on type and sequence
    function filterPayload(payload, type) {
        return payload.filter((item) => {
            if (item.type === type && item.seq === 1) {
                return true;
            } else if (item.type === type && item.seq > 1 && (item.status === "PENDING" || item.status === "STOCK-RECEIVED")) {
                const previousTask = payload.find(
                    (prevItem) => prevItem.orderref === item.orderref && prevItem.seq === item.seq - 1 && prevItem.status === "FINISHED"
                );
                return !!previousTask;
            }
            return false;
        });
    }

    // Refresh tasks and apply filtering
    const refreshTasks = async () => {
        const allTasks = await getTasks();
        const filtered = filterPayload(allTasks, type);
        setTasks(allTasks);
        setsectiontasks(filtered);
    };

    const getLStock = async (id) => {
        const stock = await getProducts(id).then(res => res[0]);
        return stock?.stock || 0;
    };

    return (
        <React.Fragment>
            <div>
                <Container fluid>
                    <Card></Card>
                    {type === "PLYSIMO" ? (
                        <PLYSIMO 
                            type={type} 
                            refresh={refreshTasks} 
                            getLStock={getLStock} 
                            sectiontasks={sectiontasks} 
                            allTasks={tasks} 
                        />
                    ) : (
                        <TRIMA
                            selectedReplace={selectedReplace}
                            openDrawer={open}
                            setopenDrawer={(e, matr) => {
                                setOpen(e);
                                setselectedReplaceindex(matr);
                            }}
                            refreshTasks={refreshTasks}
                            type={type}
                            refresh={refreshTasks}
                            setsectionTasks={setsectiontasks}
                            getLStock={getLStock}
                            sectiontasks={sectiontasks}
                            allTasks={tasks}
                        />
                    )}
                </Container>
                <MyDrawer
                    open={open}
                    position={'right'}
                    onClose={() => setOpen(false)}
                    style={{ width: 'calc(100% + 20px)' }}
                    filteredMaterials={filteredMaterials}
                    selectedStep={(e) => setselectedReplace({ toreplace: selectedReplaceindex, new: e })}
                    setSearch={setSearch}
                />
            </div>
        </React.Fragment>
    );
};

Pressa.propTypes = {
    location: PropTypes.object,
    history: PropTypes.object,
};

export default Pressa;
