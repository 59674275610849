import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Input,
  FormGroup,
  Label,
  Button,
} from "reactstrap"
import ReactDrawer from 'react-drawer';
import PropTypes from "prop-types"
import { totalCost } from "../../helpers/api_helper";
// Import Editor
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { getCodes, patchMaterial, postMaterial, getTypes, getMaterials, getProviders, getProducts, postInvoice } from "../../helpers/api_helper"
import InvoiceTable from "./invoice-table";
import { toNumber } from "lodash";

const StockMaterial = (props) => {
  document.title = "Create Material | kerino";

  const [providerid, setproviderid] = useState(0)
  const [providername, setprovidername] = useState("")
  const [providers, setprovidersList] = useState("")
  const [invnumber, setinvnumber] = useState("")
  const [startDate, setstartDate] = useState()
  const [products, setproducts] = useState([])
  const [open, setOpen] = useState(false);
  const [materialsList, setMaterialsList] = useState([])
  const [search, setSearch] = useState('');
  const [filteredMaterials, setFilteredMaterials] = useState([])
  const [codes, setcodes] = useState([])
  const [refcode, setrefcode] = useState("")
  const [types, settypes] = useState([])
  const [totals, settotals] = useState({})
  const [type, settype] = useState(false)
  const startDateChange = date => {
    setstartDate(date)
  }

  const sumArray = (array, type) => {
    const sumall = array.map(item => item[type] && toNumber(item[type])).reduce((prev, curr) => prev + curr, 0);
    return sumall
  }



  useEffect(() => {
    const totalPrdAmmount = sumArray(products, "totalPrdAmmount")
    const totalVat = sumArray(products, "vatProducts")
    const totalQuantity = sumArray(products, "quantity")
    settotals({ totalPrdAmmount: totalPrdAmmount, totalVat: totalVat, totalQuantity: totalQuantity ,totalOrder:  Number(totalPrdAmmount) + Number(totalVat)})

  }, [products]);

  useEffect(() => {
    if (search) {
      const searchRegex = new RegExp(search, 'i');
      const filtered = materialsList && materialsList.filter((cust) =>
        searchRegex.test(cust.name) ||
        searchRegex.test(cust.description)
      )
      setFilteredMaterials(filtered);
    } else {
      setFilteredMaterials(materialsList);
    }
  }, [search, materialsList]);

  useEffect(() => {
    getTypes().then(res => settypes(res))
    getMaterials().then(res => setMaterialsList(res))
    getProviders().then(res2 => setprovidersList(res2))
    getCodes().then(res2 => setcodes(res2))

  }, [])


  const getType = () => {
    if (refcode == "60.00.00" || refcode == "71.00.00" || refcode == "24.00.00" || refcode == "64.00.00") {
      return false
    }
    else {
      return true
    }
  }
  const createInvoice = () => {
    if (refcode == "71.00.00" || refcode == "24.00.00" || refcode == "64.00.00" || refcode == "24.00.02") {
      products.map((prds, i) => {
        let newArr = [...products];
        newArr[i].vatProducts = 0
        setproducts(newArr);
      }
      )
    }
    const payload = {
      "resource": [
        {
          "providerid": providerid,
          "providername": providername,
          "importDate": startDate,
          "number": invnumber,
          "type": "INBOUND",
          "content": JSON.stringify(products),
          "refcode": refcode,
          "totals": JSON.stringify(totals)
        }
      ],
    }


    postInvoice(payload).then(res => {
      if (res && res.error) {
        //console.log(res)
        if (res.error.code === 401) {
          localStorage.removeItem("authUser")
          props.history.push("/login")
        }
      }
      if (res.resource && res.resource[0]) {
        products && products.map((prd, i) => {
          updateStock(prd)
          if (products.length == i + 1) {
            props.history.push("/invoices")
          }
        })
      }
    })
  }

  //meta title
  const updateStock = async (prd) => {
    const payload = {
      "resource": [

        {
          "id": prd.id,
          "stock": parseInt(prd.stock) + parseInt(prd.quantity),
          "costprice": prd.costpricenew
        }
      ],
    }

    patchMaterial(payload).then(res => {
      if (res && res.error) {
        //console.log(res)
      }
      if (res && res.resource[0]) {
        totalCost()
        return "updated"
      }
    })
  }

  return (
    <>
      <div className="page-content" style={{ backgroundColor: getType() ? "#CDEFA4" : "#DBDBDB" }}>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Tasks" breadcrumbItem="Inbound Invoice" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>


                  <div data-repeater-list="outer-group" className="outer">
                    <div data-repeater-item className="outer">
                      <FormGroup className="mb-4" row>
                        <Label className="col-form-label col-lg-2">
                          Select PROMITHEYTI
                        </Label>
                        <Input
                          value={providerid}
                          onChange={(e) => {
                            const selectedId = e.target.value;
                            setproviderid(selectedId);
        
                            // Find the provider based on the selected ID
                            const selectedProvider = providers && providers.find((cust) => Number(cust.id) === Number(selectedId));

                            // Set the provider name if a match is found
                            if (selectedProvider) {
                              setprovidername(selectedProvider.name);
                            }
                          }}
                          type="select"
                          name="select"
                          id="exampleSelect"
                        >
                          {providers && providers.map((cust, i) => (
                            <option value={cust.id} key={i}>{cust.name}</option>
                          ))}
                        </Input>
                      </FormGroup>

                      <FormGroup className="mb-4" row>
                        <Label className="col-form-label col-lg-2">
                          Select Category
                        </Label>
                        <Input
                          value={refcode}
                          onChange={(e) => setrefcode(e.target.value)}
                          type="select"
                          name="select"
                          id="exampleSelect">
                          {codes && codes.map((cust, i) => (
                            <option value={cust.code} key={i}>{cust.name}-{cust.code}</option>
                          ))}
                        </Input>
                      </FormGroup>

                      <Button onClick={() => setOpen(true)}>Add Item</Button>

                      <FormGroup className="mb-4" row>
                        <label
                          htmlFor="taskbudget"
                          className="col-form-label col-lg-2"
                        >
                          Invoice Number
                        </label>
                        <div className="col-lg-10">
                          <Input
                            id="taskbudget"
                            name="taskbudget"
                            type="number"
                            value={invnumber}
                            onChange={(e) => setinvnumber(e.target.value)}
                            placeholder="Invoice Number"
                            className="form-control"
                          />
                        </div>
                      </FormGroup>
                      <FormGroup className="mb-4" row>
                        <Label className="col-form-label col-lg-2">
                          Invoice Date
                        </Label>
                        <Col lg="10">
                          <Row>
                            <Col md={6} className="pr-0">
                              <DatePicker
                                className="form-control"
                                selected={startDate}
                                onChange={startDateChange}

                              />
                            </Col>

                          </Row>
                        </Col>
                      </FormGroup>
                      <Row>
                        <InvoiceTable products={products} setproducts={setproducts}> </InvoiceTable>
                      </Row>

                    </div>
                  </div>
                  <Row className="justify-content-end">
                    <Col lg="10">

                      {products[0] && invnumber.length > 3 && providerid > 0 && refcode.length > 1 && 
                        <Button onClick={() => {
                          createInvoice()

                        }} type="submit" color="primary">
                          Create Invoice
                        </Button>}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col>
            </Col>
          </Row>

          <Row>

            {totals && totals.totalPrdAmmount && <Col>Total items:{totals.totalQuantity}  Total Ammount: {totals.totalPrdAmmount}</Col>}

          </Row>
          <Row>
            {totals && (refcode == "71.00.01" || refcode == "24.00.01" || refcode == "64.00.01") &&
              <Col> Vat : {totals.totalVat}  Total InclVAT : {totals.totalVat + totals.totalPrdAmmount}  </Col>}
          </Row>

        </Container>
        <ReactDrawer
          open={open}
          position={'right'}
          onClose={() => setOpen(false)}
          style={{ width: 'calc(100% + 20px)' }}
        >
          <Row style={{ margin: 10 }}>
            <Input onChange={(e) => setSearch(e.target.value)} placeholder="Search"></Input>
          </Row>
          <div style={{ margin: 10, maxHeight: '90%', overflowY: 'auto' }}>
            <Row style={{ margin: 10, backgroundColor: '#f9f9c4', borderTop: '2px solid #ddd' }}>
              <Col lg="10">Description</Col>
              <Col lg="2">Units</Col>
            </Row>
            {filteredMaterials && filteredMaterials.map((prd, i) => (
              <Row
                key={i}
                onClick={() => {
                  Object.assign(prd, { quantity: 0 })
                  products.push(prd)
                  setOpen(false)
                }}
                onMouseOver={() => {
                  document.getElementById(`row${i}`).style.backgroundColor = "#cccccc";
                }}
                onMouseLeave={() => {
                  document.getElementById(`row${i}`).style.backgroundColor = i % 2 === 0 ? '#DBDAF8' : '#fff';
                }}
                style={{ paddingTop: 5, paddingBottom: 5, backgroundColor: i % 2 === 0 ? '#EBEBF6' : '#fff', borderTop: '3px solid #ddd' }}
                id={`row${i}`}
              >
                <Col lg="10">{prd.name}</Col>
                <Col lg="2">{prd.unit}</Col>
              </Row>
            ))}
          </div>

        </ReactDrawer>


      </div>
    </>
  )
}
StockMaterial.propTypes = {
  history: PropTypes.object,
}

export default StockMaterial
