import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import {
  Table,
  Input,
  Button
} from "reactstrap"
import "react-datepicker/dist/react-datepicker.css"

import { getMaterials } from "helpers/api_helper"
import { orders } from "common/data"

const SectionTable = (props) => {
  const { tasks } = props;
  const [selectionindex, setselectionindex] = useState("");
  const [filteredMaterials, setFilteredMaterials] = useState([])

  return (
    <>
      <Table className="align-middle mb-0">
        <thead>
          <tr>
            <th>Προϊόν</th>
            <th>Ποσότητα</th>
            <th>Ημερομηνία Αναμονής</th>
            <th>Κατάσταση</th>
            <th>Αριθμός Παραγγελίας</th>
            <th>Όνομα Πελάτη</th>
            <th>Ημερομηνία Αναμενόμενης Παράδοσης</th>
          </tr>
        </thead>
        <tbody>
          {tasks && tasks.map((prds, i) => (
            <tr 
              onClick={() => {
                props.clickedItem(prds)
                setselectionindex(i);
              }} 
              style={{ backgroundColor: selectionindex === i ? "#EDEDEA" : "white" }} 
              key={i}>
              <td>{prds.name}</td>
              <td>{prds.quantity}</td>
              <td>{prds.expectedDate}</td>
              <td>{prds.status}</td>
              <td>{prds.order.id}</td>
              <td>{prds.order.name}</td>
              <td>{prds.order.expected_delivery_date}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  )
}

SectionTable.propTypes = {
  tasks: PropTypes.array,
  clickedItem: PropTypes.func,
  checkHistory: PropTypes.func
}

export default withRouter(SectionTable)
