import React, { useRef } from 'react';
import PropTypes from 'prop-types';

function InvoiceTemplate({ order }) {
  const { totalOrder, totalItems, totalVat } = JSON.parse(order.totals);
  const products = JSON.parse(order.products);
  const loans = JSON.parse(order.loans);
  const services = JSON.parse(order.services);

  const printRef = useRef(null);

  const handlePrint = () => {
    window.print(); // This triggers the print dialog
  };

  return (
    <div>
      <div
        id="print-invoice"
        ref={printRef}
        style={{
          padding: '20px',
          fontFamily: 'Arial, sans-serif',
          margin: '0 auto',
          width: '80%',
          backgroundColor: '#f9f9f9'
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
          <div>
            {/* Customer Information (top left) */}
            <h2 style={{ margin: '0 0 10px' }}>Customer Details</h2>
            <p><strong>Name:</strong> {order.name}</p>
            <p><strong>Order Number:</strong> {order.id}</p>

          </div>
          <div style={{ textAlign: 'right' }}>
            {/* Company Information (top right) */}
            <h2 style={{ margin: '0 0 10px' }}>Kerino Athens</h2>
            <p>Mixalakopoulou 7, Athens</p>
            <p>GR3423423423423</p>
            <p>Phone: 2819238123</p>
          </div>
        </div>

        {/* Order Details Table */}
        <h3 style={{ borderBottom: '2px solid #000', paddingBottom: '10px' }}>Order Details</h3>
        <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '20px' }}>
          <thead>
            <tr>
              <th style={{ padding: '10px', backgroundColor: '#f4f4f4', textAlign: 'left' }}>Item</th>
              <th style={{ padding: '10px', backgroundColor: '#f4f4f4', textAlign: 'left' }}>Quantity</th>
              <th style={{ padding: '10px', backgroundColor: '#f4f4f4', textAlign: 'left' }}>Price</th>
              <th style={{ padding: '10px', backgroundColor: '#f4f4f4', textAlign: 'left' }}>Total</th>
              <th style={{ padding: '10px', backgroundColor: '#f4f4f4', textAlign: 'left' }}>VAT</th>

              <th style={{ padding: '10px', backgroundColor: '#f4f4f4', textAlign: 'left' }}>TotalVat</th>
            </tr>
          </thead>
          <tbody>
            {products.length > 0 && (
              <>
    
                {products.map((product, index) => (
                  <tr key={index}>
                    <td style={{ padding: '10px' }}>{product.name}</td>
                    <td style={{ padding: '10px' }}>{product.quantity}</td>
                    <td style={{ padding: '10px' }}>{product.salesprice}</td>
                    <td style={{ padding: '10px' }}>{product.totalPrdAmmount}</td>
                    <td style={{ padding: '10px' }}>{product.vatProducts}</td>
                    <td style={{ padding: '10px' }}>{Number(product.totalPrdAmmount) + Number(product.vatProducts)}</td>
                  </tr>
                ))}
              </>
            )}

            {loans.length > 0 && (
              <>
                <tr>
                  <td colSpan="5" style={{ fontWeight: 'bold', textAlign: 'center', padding: '10px', backgroundColor: '#f0f0f0' }}>Loans</td>
                </tr>
                {loans.map((loan, index) => (
                  <tr key={index}>
                    <td style={{ padding: '10px' }}>{loan.name}</td>
                    <td style={{ padding: '10px' }}>{loan.quantity}</td>
                    <td style={{ padding: '10px' }}>{loan.salesprice}</td>
                    <td style={{ padding: '10px' }}></td>
                    <td style={{ padding: '10px' }}></td>
                  </tr>
                ))}
              </>
            )}

            {services.length > 0 && (
              <>
                <tr>
                  <td colSpan="5" style={{ fontWeight: 'bold', textAlign: 'center', padding: '10px', backgroundColor: '#f0f0f0' }}>Services</td>
                </tr>
                {services.map((service, index) => (
                  <tr key={index}>
                    <td style={{ padding: '10px' }}>{service.name}</td>
                    <td style={{ padding: '10px' }}>{service.quantity}</td>
                    <td style={{ padding: '10px' }}>{service.salesprice}</td>
                    <td style={{ padding: '10px' }}></td>
                    <td style={{ padding: '10px' }}></td>
                  </tr>
                ))}
              </>
            )}
          </tbody>
        </table>

        {/* Totals */}
        <div style={{ textAlign: 'right', marginTop: '20px' }}>
          <p><strong>Total Items:</strong> {totalItems}</p>
          <p><strong>Total VAT:</strong> {totalVat}</p>
          <p><strong>Total Order:</strong> {totalOrder}</p>
        </div>
      </div>

      {/* Print Button */}

    </div>
  );
}

InvoiceTemplate.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    expected_delivery_date: PropTypes.string,
    products: PropTypes.string.isRequired,
    loans: PropTypes.string,
    services: PropTypes.string,
    totals: PropTypes.string.isRequired,
    shipping: PropTypes.string,
  }).isRequired,
};

export default InvoiceTemplate;
