import React, { useState, useEffect } from 'react';
import { Table, Button, FormGroup, Input, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { patchOrder } from "helpers/api_helper";
import { printInvoice } from './PrintInvoice';


function ShippingTable({ orders, onUpdateSelectedOrders }) {
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [sharedDetails, setSharedDetails] = useState({
    driver: '',
    type: '',
    car: '',
  });
  const [extraInfo, setExtraInfo] = useState({});

  // Update state for shared fields
  const handleSharedFieldChange = (event) => {
    const { name, value } = event.target;
    setSharedDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  // Update state for each order's extra info field
  const handleExtraInfoChange = (orderId, value) => {
    setExtraInfo((prevInfo) => ({
      ...prevInfo,
      [orderId]: value,
    }));
  };

  const handleCheckboxChange = (orderId) => {
    setSelectedOrders((prevSelectedOrders) =>
      prevSelectedOrders.includes(orderId)
        ? prevSelectedOrders.filter((id) => id !== orderId)
        : [...prevSelectedOrders, orderId]
    );
  };

  useEffect(() => {
    // If there's at least one selected order, pre-fill shared details
    if (selectedOrders.length > 0) {
      const firstOrder = orders.find(order => order.id === selectedOrders[0]);
      if (firstOrder && firstOrder.shipping) {
        const shippingInfo = JSON.parse(firstOrder.shipping);
        setSharedDetails({
          driver: shippingInfo.driver || '',
          type: shippingInfo.type || '',
          car: shippingInfo.car || '',
        });
      }
    } else {
      setSharedDetails({ driver: '', type: '', car: '' });
    }
  }, [selectedOrders, orders]);

  const updateOrders = async () => {
    const payload = selectedOrders.map(orderId => ({
      id: orderId,
      status: "ON SHIPPING",  // "ON SHIPPING" translated
      shipping: JSON.stringify({
        ...sharedDetails,
        extra: extraInfo[orderId] || '',  // Add per-order extra info
      }),
      shipping_by: sharedDetails.driver
    }));

    try {
      const res = await Promise.all(payload.map(orderData => patchOrder({ resource: [orderData] })));
      if (res) {
        onUpdateSelectedOrders();
      }
    } catch (error) {
      console.error('Σφάλμα κατά την ενημέρωση των παραγγελιών:', error);
    }
  };

  return (
    <div>
      <Table>
        <thead>
          <tr>
            <th>Κωδικός Παραγγελίας</th> {/* "Order ID" */}
            <th>Όνομα</th> {/* "Name" */}
            <th>Σύνολο</th> {/* "Total" */}
            <th>Είδη</th> {/* "Items" */}
            <th>Κατάσταση</th> {/* "Status" */}
            <th>Print</th> {/* "Status" */}
          </tr>
        </thead>
        <tbody>
          {orders.map((order) => {
            const { totalOrder, totalItems } = JSON.parse(order.totals);
            return (
              <React.Fragment key={order.id}>
                <tr onClick={() => handleCheckboxChange(order.id)}>
                  <td>{order.id}</td>
                  <td>{order.name}</td>
                  <td>{totalOrder}</td>
                  <td>{totalItems}</td>
                  <td>{order.status}</td>
              
                    <td>
                      <Button style={{padding:0}}color="link" onClick={() => printInvoice(order)} title="Print Invoice">
                        <i className='bx bx-printer' style={{ fontSize: '20px' }}></i> {/* You can adjust the size */}
                      </Button>
                    </td>
                 
                </tr>
                {selectedOrders.includes(order.id) && (
                  <tr>
                    <td colSpan="5">
                      <FormGroup>
                        <Row>
                          <Col>
                            <Input
                              type="select"
                              name="type"
                              value={sharedDetails.type}
                              onChange={handleSharedFieldChange}
                            >
                              <option value="">Επιλέξτε Τύπο</option> {/* "Select Type" */}
                              <option value="Driver">Οδηγός</option> {/* "Driver" */}
                              <option value="Courier">Ταχυμεταφορέας</option> {/* "Courier" */}
                              <option value="ACS">ACS</option>
                              <option value="DHL">DHL</option>
                            </Input>
                          </Col>
                          <Col>
                            <Input
                              type="select"
                              name="driver"
                              value={sharedDetails.driver}
                              onChange={handleSharedFieldChange}
                            >
                              <option value="">Επιλέξτε Οδηγό</option> {/* "Select Driver" */}
                              <option value="Nikos">Νίκος</option>
                              <option value="Giannis">Γιάννης</option>
                            </Input>
                          </Col>
                          <Col>
                            <Input
                              type="select"
                              name="car"
                              value={sharedDetails.car}
                              onChange={handleSharedFieldChange}
                            >
                              <option value="TAXI">TAXI</option>
                              <option value="HN5555">HN5555</option>
                              <option value="KERINO - Hhy4444">KERINO - Hhy4444</option>
                            </Input>
                          </Col>
                          <Col>
                            <Input
                              type="text"
                              placeholder="Επιπλέον Πληροφορίες"
                              value={extraInfo[order.id] || ''}
                              onChange={(e) => handleExtraInfoChange(order.id, e.target.value)}
                            />
                          </Col>
                        </Row>
                      </FormGroup>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            );
          })}
        </tbody>
      </Table>
      {selectedOrders.length > 0 && (
        <Button color="primary" onClick={updateOrders}>
          Αποστολή Επιλεγμένων Παραγγελιών {/* "Ship Selected Orders" */}
        </Button>
      )}
    </div>
  );
}

ShippingTable.propTypes = {
  orders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      totals: PropTypes.string.isRequired,  // Changed 'totals' type to 'string' to match usage
      status: PropTypes.string.isRequired,
      shipping: PropTypes.string,  // Assumed 'shipping' is a JSON string
    })
  ).isRequired,
  onUpdateSelectedOrders: PropTypes.func.isRequired,
};

export default ShippingTable;
