import {
  GET_CUSTOMERS_FAIL,
  GET_CUSTOMERS_SUCCESS,
  GET_CUSTOMER_DETAIL_SUCCESS,
  GET_CUSTOMER_DETAIL_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  customers: [],
  customerDetail: {},
  error: {},
}

const Customers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CUSTOMERS_SUCCESS:
      return {
        ...state,
        customers: action.payload,
      }

    case GET_CUSTOMERS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_CUSTOMER_DETAIL_SUCCESS:
      return {
        ...state,
        customerDetail: action.payload,
      }

    case GET_CUSTOMER_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default Customers
