import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_CUSTOMERS, GET_CUSTOMER_DETAIL } from "./actionTypes"
import {
  getCustomersSuccess,
  getCustomersFail,
  getCustomerDetailSuccess,
  getCustomerDetailFail,
} from "./actions"

// //Include Both Helper File with needed methods
// import { getInvoices, getInvoiceDetail } from "helpers/fakebackend_helper"


import{getCustomers,getCustomerDetail} from "helpers/api_helper"

function* fetchCustomers() {
  // //console.log("fireSaga")
  try {
    const response = yield call(getCustomers)
    yield put(getCustomersSuccess(response))
  } catch (error) {
    yield put(getCustomersFail(error))
  }
}

function* fetchCustomerDetail({ invoiceId }) {
  try {
    const response = yield call(getCustomerDetail, invoiceId)
    yield put(getCustomerDetailSuccess(response))
  } catch (error) {
    yield put(getCustomerDetailFail(error))
  }
}

function* CustomersSaga() {
  yield takeEvery(GET_CUSTOMERS, fetchCustomers)
  yield takeEvery(GET_CUSTOMER_DETAIL, fetchCustomerDetail)
}

export default CustomersSaga
