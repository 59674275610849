import React, { useState, useEffect } from "react"
import { Modal, ModalHeader, ModalBody, Input, Table, Button } from 'reactstrap';
import PropTypes from "prop-types"

 export function CustomerSelect({ customers, onSelect }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [customerid, setCustomerid] = useState('');
  const [modalOpen, setModalOpen] = useState(false);

  const filteredCustomers = customers && customers.filter((cust) => {
    const searchRegex = new RegExp(searchTerm, 'i');
    return (
      searchRegex.test(cust.name) ||
      searchRegex.test(cust.description) ||
      searchRegex.test(cust.phone) ||
      searchRegex.test(cust.vat)
    );
  });

  const handleSelect = (id) => {
    setCustomerid(id);
    setModalOpen(false);
    onSelect(id);
  };

  return (
    <>
      <Button style={{maxWidth:100}}color="primary" onClick={() => setModalOpen(true)}>Select </Button>
      <Modal style={{minWidth:800}}isOpen={modalOpen} toggle={() => setModalOpen(false)}>
        <ModalHeader toggle={() => setModalOpen(false)}>Select a Customer</ModalHeader>
        <ModalBody>
          <Input
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            type="text"
            name="search"
            id="exampleSearch"
            placeholder="Search customers..."
            className="mb-3"
          />
          <Table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Phone</th>
                <th>VAT</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {filteredCustomers && filteredCustomers.map((cust, i) => (
                <tr key={i}>
                  <td>{cust.name}</td>
           
                  <td>{cust.phone}</td>
                  <td>{cust.vat}</td>
                  <td>
                    <Button color="primary" onClick={() => handleSelect(cust.id)}>Select</Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </ModalBody>
      </Modal>
    </>
  );
}
CustomerSelect.propTypes = {
    customers: PropTypes.array,
    onGetInvoices: PropTypes.func,
    history: PropTypes.object,
    names: PropTypes.string,
    onSelect:PropTypes.func
  
  }
  export default CustomerSelect