import React, { useEffect, useState } from "react"
import { Link, withRouter } from "react-router-dom"
import { Card, CardBody, Col, Container, Row, CardTitle, Button, Table } from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import ReactApexChart from "react-apexcharts"
import PropTypes from "prop-types"
//Import Images


import { getTasks as onGetTasks } from "../../store/tasks/actions"
import { options, series, } from "common/data/tasks"

//redux
import { useSelector, useDispatch } from "react-redux"
import { getProducts, getOrdersbyid } from "helpers/api_helper"


const TasksList = (props) => {

  //meta title
  const [selectedRow, setSelectedRow] = useState(null);
  const [byOrders, setbyOrders] = useState(null);

  document.title = "Task List | kerino - React Admin & Dashboard Template"
  const dispatch = useDispatch()
  const { tasks } = useSelector(state => ({
    tasks: state.tasks.tasks,
  }))

  useEffect(() => {
    dispatch(onGetTasks())
  }, [dispatch])


  const handleRowClick = (rowIndex) => {
    setSelectedRow(selectedRow === rowIndex ? null : rowIndex);
  };

  function createOrderTaskObjects(payload) {
    //makis
    const orders = {};
    payload.forEach(({ orderref, name, id, type, status, photos, seq,quantity }) => {
      if (!orders[orderref]) {
        orders[orderref] = { orderref, tasks: [] };
      }
      orders[orderref].tasks.push({ name, id, type, status, photos, seq,quantity });
    });
    const orderedOrders = Object.values(orders).map(({ orderref, tasks }) => {
      return {
        orderref,
        tasks: tasks.sort((a, b) => a.seq - b.seq),
      };
    });
    return orderedOrders.sort((a, b) => a.tasks[0].seq - b.tasks[0].seq);
  }

  function getUniqueTasksByOrderRef(tasks) {
    const uniqueTasks = {};
    tasks.forEach(task => {
      if (!uniqueTasks.hasOwnProperty(task.orderref)) {
        uniqueTasks[task.orderref] = task;
      }
    });
    return Object.values(uniqueTasks);
  }

  const expandedData = createOrderTaskObjects(tasks).filter(
    (item, i) => i === selectedRow);

  const orders = getUniqueTasksByOrderRef(tasks)
  const parseJson = (array) => {
    return JSON.parse(array)
  }


  const renderActions = (order) => {
    return (
      <div>
        <Row>
          <Col>
            {order.tasks && order.tasks.map((tsk, i) => (
              <Row
                onClick={() =>  props.history.push("/section", tsk.type)}
                style={{
                  marginTop: 10,
                  backgroundColor: tsk.status === 'PENDING' ? 'lightcoral' : tsk.status === 'FINISHED' ? 'lightgreen' : 'inherit',
                }}
                key={i}
              >
                <Col>
                  <Row>Task ID: {tsk.id}</Row>
                  <Row>Task Section: {tsk.type}</Row>
                  <Row>Task Name: {tsk.name}</Row>
                  <Row>Task Quantity: {tsk.quantity}</Row>
                  <Row>Task Status: {tsk.status}</Row>
                  <Row>Task Order: {tsk.seq}</Row>
                </Col>
                <Col>
                  {tsk.photos && parseJson(tsk.photos)[0] && (
                    <Row>
                      {parseJson(tsk.photos).map((pht, i) => (
                        <img key={i} src={pht} style={{ margin: 5, width: '100px', height: '100px' }} />
                      ))}
                    </Row>
                  )}
                </Col>
              </Row>
            ))}
          </Col>
        </Row>
      </div>
    );
  };
  
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Tasks" breadcrumbItem="Task List" />
          {/* Render Breadcrumbs */}
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">PRODUCTION TASKS BY ORDER  </h4>
                  <div className="table-responsive">
                    <Table>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Order Id</th>
                          <th>Customer</th>
                   
                          <th>ExpectedDate</th>
                          <th>Status</th>
  
                        </tr>
                      </thead>
                      <tbody>
                        {orders &&
                          orders.map((tsk, i) => (
                            <React.Fragment key={i}>
                              <tr onClick={() => handleRowClick(i)}>
                                <th scope="row">{i + 1}</th>
                                <td>{tsk.orderref}</td>
                          
                                <td>{tsk.customer_name}</td>
                                <td>{tsk.expectedDate}</td>
                                <td>
                                  <div className="text-center">
                                    {tsk.status === "PENDING" &&
                                      <span className="badge rounded-pill badge-soft-danger font-size-11">{tsk.status}</span>}
                                    {tsk.status === "STARTED" &&
                                      <span className="badge rounded-pill badge-soft-info font-size-11">{tsk.status}</span>}
                                    {tsk.status === "PRODUCTION" &&
                                      <span className="badge rounded-pill badge-soft-info font-size-11">{tsk.status}</span>}
                                    {tsk.status === "DELIVERY" &&
                                      <span className="badge rounded-pill badge-soft-warning font-size-11">{tsk.status}</span>}
                                    {tsk.status === "FINISHED" &&
                                      <span className="badge rounded-pill badge-soft-success font-size-11">{tsk.status}</span>}
                                  </div>
                                </td>
                              </tr>

                              {selectedRow === i && (
                                <tr>
                                  <td colSpan={6}>{renderActions(expandedData[0])}</td>
                                </tr>
                              )}
                            </React.Fragment>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>

          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
TasksList.propTypes = {
  history: PropTypes.object,
  setsectionTasks: PropTypes.func,
  sectiontasks: PropTypes.array,
  refresh: PropTypes.func,
  type: PropTypes.string,
  allTasks: PropTypes.any,
  setallTasks: PropTypes.any,
  openDrawer: PropTypes.any,
  setopenDrawer: PropTypes.any,
  selectedReplace: PropTypes.any


}

export default withRouter(TasksList)
