export function countOrders(orders) {
    let totalOrders = 0;
    let totalEpsilon = 0;
    let totalRed = 0;
  
    orders.forEach(order => {
      totalOrders++;
      if (order.invoicedAt && order.invoicedAt.includes("epsilon")) {
        totalEpsilon++;
      } else if (order.invoicedAt && order.invoicedAt.includes("red")) {
        totalRed++;
      }
    });
  
    return {
      totalOrders: totalOrders,
      totalEpsilon: totalEpsilon,
      totalRed: totalRed
    };
  }
  export  function sumTotalOrder(orders) {
    return orders.reduce((acc, curr) => {
      const { totalOrder } = JSON.parse(curr.totals);
      return acc + Number(totalOrder);
    }, 0);
  }

  export function countOrdersByDate(orders) {
    const ordersByDate = {};
    
    orders.forEach((order) => {
      const date = order.updated.split('T')[0];
      
      if (!ordersByDate[date]) {
        ordersByDate[date] = 1;
      } else {
        ordersByDate[date] += 1;
      }
    });
    
    const result = [];
    
    Object.keys(ordersByDate).forEach((date) => {
      result.push({
        date: date,
        count: ordersByDate[date]
      });
    });
    
    return result;
  }

  export default {countOrders,sumTotalOrder,countOrdersByDate}