import React, { useEffect ,useState} from "react"
import { Col, Container, Row } from "reactstrap"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import { map } from "lodash"
import InvoicesTable from "./invoicesTable"

//redux
import { useSelector, useDispatch } from "react-redux"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//Import Card invoice
import CardInvoice from "./card-invoice"
// import { getInvoices as onGetInvoices } from "store/actions"
import {patchMaterial, postMaterial, getTypes, getMaterials, getProviders, getProducts, getInvoices } from "../../helpers/api_helper"
import InvoiceTable from "./invoice-table"


const InvoicesList = props => {
   //meta title
   document.title="Invoice List | customers - React Admin & Dashboard Template";
  const [invoices, setinvoices] = useState([])

  useEffect(() => {
    getInvoices().then(res => setinvoices(res))
  }, [])



  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="customers" breadcrumbItem="Invoices List" />
          <Row>
            <InvoicesTable invoices={invoices} ></InvoicesTable>
            {/* {map(invoices, (invoice, key) => (
              <CardInvoice data={invoice} key={"customer" + key} />

            ))} */}
          </Row>
          <Row>
            <Col xs="12">
              <div className="text-center my-3">
  
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

InvoicesList.propTypes = {
  invoices: PropTypes.array,
  onGetInvoices: PropTypes.func,
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
    ...state
  }
}


export default withRouter(InvoicesList)
