

async function refreshToken() {

  // localStorage.removeItem('refreshToken');
  // localStorage.removeItem('jwt');
  // return
  const jwt = localStorage.getItem('jwt');
  const refreshToken = localStorage.getItem('refreshtoken');
  if (!refreshToken || !jwt) {
    console.log("NO JWT on localstorage")
    const jwt = await loginAndGetRefreshToken()
    console.log(jwt)
    return jwt;
  }
  const url = 'http://be.kerinoath.com:5000/Token/Refresh';
  const headers = {
    'Accept': 'text/plain',
    'Content-Type': 'application/json'
  };

  const data = JSON.stringify({
    'token': jwt,
    'refreshToken': refreshToken
  });

  const options = {
    method: 'POST',
    headers: headers,
    body: data
  };

  try {
    const response = await fetch(url, options);
    const newAccessToken = await response.text();
    const parsed = JSON.parse(newAccessToken)
    if (parsed.status === 403) {
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('jwt');
      console.log("REFRESH TOKEN INVIALID- SENDING TO LOGIN ")
      const jwt = await loginAndGetRefreshToken();
      return jwt
    }
    //  localStorage.setItem('jwt', parsed.jwt);
    //  localStorage.setItem('refreshtoken', parsed.jwtRefreshToken);
    return parsed.jwt;
  } catch (error) {
    // console.log(error.status);
  }
}


async function loginAndGetRefreshToken() {
  const url = 'http://be.kerinoath.com:5000/Account/LoginToSubscription';
  const headers = {
    'Accept': 'text/plain',
    'Content-Type': 'application/json'
  };
  const data = JSON.stringify({
    'subscriptionKey': 'FEE28DEA4A4245D9ABD7E7B7889D992A',
    'email': 'FD3BA0D30862498DB2D6',
    'password': '%+?R9fQcFk9duYKuUa5o'
  });

  const options = {
    method: 'POST',
    headers: headers,
    body: data
  };

  try {
    const response = await fetch(url, options);
    const refreshToken = await response.text();
    const parsed = JSON.parse(refreshToken)
    localStorage.setItem('jwt', parsed.jwt);
    localStorage.setItem('refreshtoken', parsed.jwtRefreshToken);
    // refreshToken();
    return parsed.jwt;
  } catch (error) {
    console.error(error);
    return null;
  }
}


function transformData(data) {
  let result = [];
  let products = JSON.parse(data);
  products.forEach(product => {
    result.push({
      'ItemID': null,
      'ItemCode': product.epsilonCode,
      'ScanCode': null,
      'VATPercent': product.vatPercent,
      'Qty': product.quantity,
      'Price': product.salesprice,
      'VATVal': product.vat,
      'TotalVal': product.totalPrdAmmount
    });
  });
  return result;
}

async function getSes(){

  fetch('http://be.kerinoath.com:5000/api/auth/refreshsession', {
  method: 'POST',
  headers: {
    'accept': '*/*',
    'accept-encoding': 'gzip, deflate, br',
    'accept-language': 'en-GB,en;q=0.9,el-GR;q=0.8,el;q=0.7,en-US;q=0.6',
    'content-type': 'application/json; charset=utf-8',
    'cookie': '_gcl_au=1.1.1683742801.1680292113; _gid=GA1.2.1580988030.1680292113; x-browserId=efea7775-e233-49f3-a236-1b87bc952ad7; _ga_ZX6XP5TX7N=GS1.1.1680372692.2.1.1680373679.0.0.0; _ga=GA1.2.1383521473.1680292113; _ga_KMZMRW1Q05=GS1.1.1680373807.5.1.1680374137.55.0.0; sidebar_toggle_state=off; _fbp=fb.1.1680421328140.432074618',
    'Origin': 'https://epsilonsmart13.epsilonnet.gr',
    'referer': 'https://epsilonsmart13.epsilonnet.gr/55d28497-51c7-6303-89fa-ca7a1cae5bf3/Dashboard/Index',
    'sec-ch-ua': '"Google Chrome";v="111", "Not(A:Brand";v="8", "Chromium";v="111"',
    'sec-ch-ua-mobile': '?0',
    'sec-ch-ua-platform': '"macOS"',
    'sec-fetch-dest': 'empty',
    'sec-fetch-mode': 'cors',
    'sec-fetch-site': 'same-origin',
    'user-agent': 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/111.0.0.0 Safari/537.36',
    'x-smart-cookie':"CfDJ8NxRby77CxtNphQrPd362t9BOI2NRELaTxVtKp4N5h7mKjf63yhv4sxLiyXQ4K5FBnUp-1N2AQe2DwH9w1u4wu0LEcN7pKsXFXea0JjlKkaKqSkPMfxrekSslAXEpEcX1_MEsyqvJpOTdEa-xvs0AvpR-SPqsGiHTfJrtEiDsfZsI9OxHjcAJSM-hZnvQiHG10c6-JDdw3ubTE2KmoQA4FKRk31n2jJqFkO2o92VUQ5WRqxRAxjBaaVNULWX1T5kmTpmANetbXmhsnxD78zChp51Tg2XikXJeUJE7pQYOWdJOrYkMs3XkXQQcLdD-Gv1bTZKwYZav4XQsmdgu3uvm3YUlT2LFq_oniFC41O2UpoOu9M7HzhCG7ANICYFF0qYi-sX3pcFlx4kP0PVwpSXibOz5odpCbFSbyux2IIPUU41APwsqUsuUyyIuR-fRS-U_GrG7_bYr24p17idFSIUO-3k5l-dUg1bx8EoO3xGwfH1r92r4MDmK2Fhb40G_hy2mk3YAh5F1y9VYK_-ZNBu31_TdbpOeyGvHEK8mCA7vgQeoNqdkrlUawFnoaQqWohwiMVUMgKKNxhe7WEXlzR-JP0Ihf6d0xgVt1trZ6OURLEeUVb_AKYzCBKYovgj5vJoPmtSsK-4bYZkyMekw6Zw1QUF_3pQmaAoBQyvrparZ6nARgp59TL8X7V5zWOYcXwQIvNxMMxNjMPMKcimm_CzzGbfnMKJRonH2sTrC0DRHF8uj8TFmbHab-Nm4rlYJy9L89kH90M7Yyi6TYuLi7ScpIbNDgWFnKxc4eY-8eNKYBogH4AGL8q9x3968pcRZg6txGNbqnPdNAgBxXQjHBuy9A4"
}
})

}
  

async function insertDocuments(order, customer) {

  const url = 'http://be.kerinoath.com:5000/api/Items/Save';

  ///api/Items/Save
 // const url = 'http://be.kerinoath.com:5000/api/Eshop/InsertDocuments';
  const token = await refreshToken();

  if (token) {

    
    const headers = {
      // 'Accept': '*/*',
      'Authorization': `Bearer ${token}`,
    //  'Accept': 'text/plain',
      'accept': '*/*',
      "Content-Type": "application/jsonpatch+json"
    };


    const data = JSON.stringify([{
      'DocDateTime': new Date(),
      'IsRetail': 0,
      'VatStatus': 1,
      'RefDocCode': null,
      'PaymentMethod': 1,
      'CustName': customer.name,
      'CustTIN': customer.vat,
      'CustStreet': customer.address,
      'CustStreetΝο': '10324',
      'CustCity': customer.city,
      'CustPostalCode': null,
      'Justification': 'Online Παραγγελία',
      'ExtraCharges': 3,
      'Lines': transformData(order.products)
    }]);
 
    const options = {
      method: 'POST',
      headers: headers,
      body: data
    };

    try {
      const response = await fetch(url, options).then(res => console.log(res));
      return response
      // const responseData = await response.json();
      // console.log(responseData);
    } catch (error) {
      console.error(error);
    }
  }

}


export { loginAndGetRefreshToken, insertDocuments }  