import React, { useState, useEffect } from "react";
import MetaTags from 'react-meta-tags';
import { Container, Table } from "reactstrap";
import { getProviders } from "helpers/api_helper"; // Αντικαταστήστε με τον δικό σας API helper
import ReactApexChart from 'react-apexcharts';

const TopProductsFromProviders = () => {
  const [productTotals, setProductTotals] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const providers = await getProviders();
        processProductData(providers);
      } catch (error) {
        console.error('Σφάλμα κατά την ανάκτηση δεδομένων:', error);
      }
    };

    fetchData();
  }, []);

  const processProductData = (providers) => {
    // Φιλτράρουμε τους προμηθευτές με παραγγελίες
    const providersWithOrders = providers.filter(provider => provider.orders.length > 0);

    // Συγκεντρώνουμε δεδομένα προϊόντων
    const productQuantities = {};

    providersWithOrders.forEach(provider => {
      provider.orders.forEach(order => {
        try {
          const products = JSON.parse(order.content);
          products.forEach(product => {
            const { name, quantity } = product;
            if (productQuantities[name]) {
              productQuantities[name] += quantity;
            } else {
              productQuantities[name] = quantity;
            }
          });
        } catch (error) {
          console.error('Σφάλμα κατά την ανάλυση των προϊόντων παραγγελίας:', error);
        }
      });
    });

    // Μετατρέπουμε το productQuantities σε πίνακα, ταξινομούμε κατά ποσότητα και παίρνουμε τα 10 κορυφαία
    const sortedProducts = Object.entries(productQuantities)
      .map(([name, quantity]) => ({ name, quantity }))
      .sort((a, b) => b.quantity - a.quantity)
      .slice(0, 10);

    setProductTotals(sortedProducts);
  };

  // Επιλογές γραφήματος
  const chartOptions = {
    chart: {
      type: 'bar',
    },
    xaxis: {
      categories: productTotals.map(product => product.name),
      title: {
        text: 'Προϊόντα'
      }
    },
    yaxis: {
      title: {
        text: 'Συνολική Ποσότητα Buy'
      },
      labels: {
        formatter: (value) => value.toFixed(0) // Μορφοποιούμε τις ετικέτες του άξονα y σε ακέραιους αριθμούς
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded'
      }
    },
    dataLabels: {
      enabled: true,
      formatter: (value) => value.toFixed(0) // Μορφοποιούμε τις ετικέτες δεδομένων σε ακέραιους αριθμούς
    },
    title: {
      align: 'center',
      style: {
        fontSize: '16px',
        fontWeight: 'bold'
      }
    }
  };

  // Δεδομένα γραφήματος
  const chartData = [{
    name: 'Συνολική Ποσότητα buys',
    data: productTotals.map(product => product.quantity)
  }];

  return (
    <React.Fragment>
      <div className="page-content">

        <Container fluid>
          <h4>Κορυφαία Υλικά από Προμηθευτές</h4>
          <div style={{ marginTop: '20px' }}>
            <ReactApexChart options={chartOptions} series={chartData} type="bar" height={350} />
          </div>

        </Container>
      </div>
    </React.Fragment>
  );
};

export default TopProductsFromProviders;
