import React, { useState, useEffect } from "react";
import MetaTags from 'react-meta-tags';
import { Container, Table } from "reactstrap";
import { getProviders } from "helpers/api_helper"; // Replace with your API helper
import ReactApexChart from 'react-apexcharts';

const ProvidersDash = () => {
  const [providerTotals, setProviderTotals] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const providers = await getProviders();
        processProviderData(providers);
      } catch (error) {
        console.error('Σφάλμα κατά την ανάκτηση δεδομένων:', error);
      }
    };

    fetchData();
  }, []);

  const processProviderData = (providers) => {
    // Φιλτράρισμα παρόχων με παραγγελίες
    const providersWithOrders = providers.filter(provider => provider.orders.length > 0);

    // Υπολογισμός συνολικών παραγγελιών, πληρωμών και πλήθους παραγγελιών
    const totals = providersWithOrders.map(provider => {
      const { totalOrders, orderCount } = provider.orders.reduce((acc, order) => {
        try {
          const parsedTotals = JSON.parse(order.totals);
          return {
            totalOrders: acc.totalOrders + (Number(parsedTotals.totalOrder) || 0),
            orderCount: acc.orderCount + 1
          };
        } catch (error) {
          console.error('Σφάλμα κατά την ανάλυση των συνολικών παραγγελιών:', error);
          return acc;
        }
      }, { totalOrders: 0, orderCount: 0 });

      const totalPayments = provider.payments.reduce((acc, payment) => acc + (Number(payment.ammount) || 0), 0);

      return {
        id: provider.id,
        name: provider.name,
        totalOrders,
        totalPayments,
        orderCount,
        totalProvider: totalOrders - totalPayments
      };
    });

    // Ταξινόμηση των συνολικών παραγγελιών κατά φθίνουσα σειρά
    const sortedTotals = totals.sort((a, b) => b.totalOrders - a.totalOrders);

    setProviderTotals(sortedTotals);
  };

  // Επιλογές γραφήματος
  const chartOptions = {
    chart: {
      type: 'bar',
      stacked: false, // Ensure bars are grouped
    },
    xaxis: {
      categories: providerTotals.map(provider => provider.name),
      title: {
        text: 'Πάροχοι'
      }
    },
    yaxis: {
      title: {
        text: 'Συνολική Αξία (€)'
      },
      labels: {
        formatter: (value) => value.toFixed(2) // Μορφοποίηση ετικετών άξονα y σε δύο δεκαδικά ψηφία
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '35%',
        endingShape: 'rounded'
      }
    },
    dataLabels: {
      enabled: true,
      formatter: (value) => value.toFixed(2) // Μορφοποίηση ετικετών δεδομένων σε δύο δεκαδικά ψηφία
    },
    title: {
      text: 'Συνολική Αξία Παραγγελιών, Πληρωμών και Υπολοίπου ανά Πάροχο',
      align: 'center',
      style: {
        fontSize: '16px',
        fontWeight: 'bold'
      }
    },
    colors: ['#33C1FF', '#FF5733', '#4CAF50'], // Blue for Total Orders, Red for Payments, Green for Total Provider
    legend: {
      position: 'top',
      horizontalAlign: 'left',
      floating: true,
      offsetY: -10
    }
  };

  // Δεδομένα γραφήματος
  const chartData = [
    {
      name: 'Συνολική Αξία Παραγγελιών',
      data: providerTotals.map(provider => provider.totalOrders)
    },

    {
      name: 'Υπόλοιπο',
      data: providerTotals.map(provider => provider.totalProvider)
    }
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Πάροχοι | Kerino - React Admin & Dashboard Template</title>
        </MetaTags>
        <Container fluid>
          <div style={{ marginTop: '20px' }}>
            <ReactApexChart options={chartOptions} series={chartData} type="bar" height={350} />
          </div>
          <h4>Σύνολα Παρόχων</h4>
          <Table className="align-middle mb-0">
            <thead>
              <tr>
                <th>Όνομα Παρόχου</th>
                <th>Σύνολο Παραγγελιών</th>
                <th>Συνολική Αξία Παραγγελιών</th>
                <th>Συνολικές Πληρωμές</th>
                <th>Υπόλοιπο</th>
              </tr>
            </thead>
            <tbody>
              {providerTotals.map((provider) => (
                <tr key={provider.id}>
                  <td>{provider.name}</td>
                  <td>{provider.orderCount}</td>
                  <td>{Number(provider.totalOrders).toFixed(2)} €</td>
                  <td>{Number(provider.totalPayments).toFixed(2)} €</td>
                  <td>{Number(provider.totalProvider).toFixed(2)} €</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ProvidersDash;
