import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import {
  Table,


} from "reactstrap"
import "react-datepicker/dist/react-datepicker.css"




const LoanOrderTable = (props) => {
  const { products } = props;


  return (
    <>
  
      <Table className="align-middle mb-0"  style={{backgroundColor:'#F1D9F3'}}>
        <thead>
          <tr>
            <th>ITEM</th>
            <th>NAME</th>
            <th>SKU</th>
            <th>UNITS</th>
            <th>QUANTITY</th>
            <th>DELETE</th>
          </tr>
        </thead>
        <tbody>
          {props.products && props.products.map((prds, i) => (
            <tr key={i}>
              <th scope="row">{i + 1}</th>
              <td>{prds.name}</td>
              <td>{prds.sku}</td>
    
              <td>{prds.unit}</td>
              <td>{prds.quantity}
  
              </td>
        
       
              <td>
                <button onClick={() => props.setproducts(products.filter((item, ind) => ind !== i))} type="button" className="btn btn-light btn-sm">Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

    </>
  )
}


LoanOrderTable.propTypes = {
  products: PropTypes.array,
  onGetInvoices: PropTypes.func,
  fixCosts: PropTypes.func,
  history: PropTypes.object,
  setproducts: PropTypes.func,
  checkHistory: PropTypes.func

}

export default withRouter(LoanOrderTable)
