import React, { useState ,useEffect} from 'react';
import { render } from 'react-dom';
// import { COUNTRIES } from './countries';

import { WithContext as ReactTags } from 'react-tag-input';
import PropTypes from "prop-types"
const suggestions = [{
    id: "Thailand",
    text: "Thailand"
}]


const KeyCodes = {
    comma: 188,
    enter: 13
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

export const TagsCustomers = (props) => {
    const [tags, setTags] = useState(props.tags);


    useEffect(() => {
props.setTags(tags)
      }, [tags])


    const handleDelete = i => {
        setTags(tags.filter((tag, index) => index !== i));
    };

    const handleAddition = tag => {
        setTags([...tags, tag]);
    };

    const handleDrag = (tag, currPos, newPos) => {
        const newTags = tags.slice();
        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);
        // re-render
        setTags(newTags);
    };

    const handleTagClick = index => {
        //console.log('The tag at index ' + index + ' was clicked');
    };

    return (
        <div >
            <div>
                <ReactTags
                    tags={tags}
                    suggestions={suggestions}
                    delimiters={delimiters}
                    tagInputField= 'tagInputFieldClass'
                    handleDelete={handleDelete}
                    handleAddition={handleAddition}
                    handleDrag={handleDrag}
                    handleTagClick={handleTagClick}
                    inputFieldPosition="bottom"
                    autocomplete
                />
            </div>
        </div>
    );
};

TagsCustomers.propTypes = {
    customers: PropTypes.array,
    onGetInvoices: PropTypes.func,
    history: PropTypes.object,
    tags:PropTypes.any,
    setTags:PropTypes.func
  }