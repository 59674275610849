// src/components/filter.
import React, { useMemo } from "react";
import PropTypes from 'prop-types';

//import components
import Breadcrumbs from '../../components/Common/Breadcrumb';
 import TableContainer from '../../components/Common/TableContainer';

function DatatableTables(props) {

    const rowClick =(e) => {


    }

const data = props.products
    const columns = useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'name',
            },

            {
                Header: 'Description',
                accessor: 'description'
            },
            {
                Header: 'Costprice',
                accessor: 'costprice'
            },
            {
                Header: 'unit',
                accessor: 'unit'
            },
            {
                Header: 'stock',
                accessor: 'stock'
            },
        ],
        []
    );


    document.title = "Data Tables | kerino - React Admin & Dashboard Template";

    return (
        <div className="page-content">
            <div className="container-fluid">
              
                {/* <Table columns={columns} data={data} /> */}
                <TableContainer
                    rowClick={"/material-overview"}
                    columns={columns}
                    data={data}
   
                    isAddOptions={false}
                    customPageSize={10}
                    className="custom-header-css"
                />
            </div>
        </div>
    );
}
DatatableTables.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
    products: PropTypes.any,
    rowClick: PropTypes.any

};


export default DatatableTables;