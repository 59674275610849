import React, { useState, useEffect } from "react";
import MetaTags from 'react-meta-tags';
import { Container } from "reactstrap";
import { getCustomers } from "helpers/api_helper"; // Replace with your API helper
import ReactApexChart from 'react-apexcharts';

const TopProducts = () => {
  const [topProducts, setTopProducts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const customers = await getCustomers();
        processProductData(customers);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const processProductData = (customers) => {
    // Filter customers with orders
    const customersWithOrders = customers.filter(customer => customer.orders.length > 0);
  
    // Initialize an object to store product sales data
    const productSales = {};
  
    customersWithOrders.forEach(customer => {
      // Filter only shipped orders
      const shippedOrders = customer.orders.filter(order => order.status === 'SHIPPED');
  
      shippedOrders.forEach(order => {
        try {
          const products = JSON.parse(order.products);
          products.forEach(product => {
            const { name, quantity } = product;
  
            if (productSales[name]) {
              productSales[name] += quantity;
            } else {
              productSales[name] = quantity;
            }
          });
        } catch (error) {
          console.error('Error parsing order products:', error);
        }
      });
    });
  
    // Convert the product sales object to an array and sort by total quantity sold
    const sortedProducts = Object.entries(productSales)
      .map(([name, quantity]) => ({ name, quantity }))
      .sort((a, b) => b.quantity - a.quantity)
      .slice(0, 10); // Get top 10 products
  
    setTopProducts(sortedProducts);
  };
  

  // Chart options
  const chartOptions = {
    chart: {
      type: 'bar',
    },
    xaxis: {
      categories: topProducts.map(product => product.name),
      title: {
        text: 'Products'
      }
    },
    yaxis: {
      title: {
        text: 'Total Quantity Sold'
      },
      labels: {
        formatter: (value) => value.toFixed(0) // Format y-axis labels as integers
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded'
      }
    },
    dataLabels: {
      enabled: true,
      formatter: (value) => value.toFixed(0) // Format data labels as integers
    },
    title: {
   
      align: 'center',
      style: {
        fontSize: '16px',
        fontWeight: 'bold'
      }
    }
  };

  // Chart data
  const chartData = [{
    name: 'Total Quantity Sold',
    data: topProducts.map(product => product.quantity)
  }];

  return (
    <React.Fragment>
      <div className="page-content">

        <Container fluid>
          <h4>Top 10 Sold Products</h4>
          <div style={{ marginTop: '20px' }}>
            <ReactApexChart options={chartOptions} series={chartData} type="bar" height={350} />
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default TopProducts;
