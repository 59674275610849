import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { insertDocuments } from "helpers/epsilon";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Table,
} from "reactstrap";
import { connect } from "react-redux";
import Breadcrumb from "../../components/Common/Breadcrumb";
import avatar from "../../assets/images/users/avatar-1.jpg";
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { getOrdersbyid, getProducts, checkOrderStock, postTask, patchOrder, patchProduct, postLoan, getTasks } from "helpers/api_helper";
import ShipModal from "./ship-modal";
import InvoiceModal from "./invoice-modal";

const OrderDetail = (props) => {
  const history = useHistory();
  const { customers } = useSelector(state => ({
    customers: state.customers.customers,

  }));

  const [order, setOrder] = useState({});
  const [stockStatus, setStockStatus] = useState([]);
  const [customer, setCustomer] = useState({});
  const [products, setProducts] = useState([]);
  const [loanProducts, setLoanProducts] = useState([]);
  const [totals, setTotals] = useState({});
  const [orderTasks, setOrderTasks] = useState([]);
  const [allTasks, setAllTasks] = useState([]);
  const [shipModal, setShipModal] = useState(false);
  const [taskInfo, setTaskInfo] = useState("");
  const [alertMessage, setAlertMessage] = useState({ type: "", message: "" });

  useEffect(() => {
    if (props.match && props.match.params && props.match.params.id) {
      const orderId = props.match.params.id;
      getOrdersbyid(orderId).then(res => {
        if (res && res[0]) {
          setOrder(res[0]);
          const customerData = customers.find(cust => cust.id === res[0].customer_id);
          setCustomer(customerData || {});
          setProducts(JSON.parse(res[0].products) || []);
          setLoanProducts(JSON.parse(res[0].loans) || []);
          setTotals(JSON.parse(res[0].totals) || {});
        }
      }).catch(error => {
        setAlertMessage({ type: "danger", message: "Σφάλμα κατά την ανάκτηση παραγγελίας." });
      });
    } else {
      history.push("/Orders");
    }
  }, [props.match, customers, history]);

  useEffect(() => {
    if (order.id) {
      const pendingTasks = allTasks.filter(task => task.orderref === order.id && task.status !== "FINISHED");
      setOrderTasks(pendingTasks);
    }
  }, [,allTasks ,order]);

  useEffect(async () => {
    const tasks = await getTasks()
    console.log(tasks)
    setAllTasks(tasks)
  }, []);


  useEffect(() => {
    checkOrderStock(products).then(res => {
      const noStock = res.filter(stk => stk.stockStatus === "STOCK-FAULT");
      setStockStatus(noStock);
    }).catch(error => {
      setAlertMessage({ type: "danger", message: "Σφάλμα κατά την επαλήθευση διαθεσιμότητας αποθέματος." });
    });
  }, [products]);

  const updateOrderStatus = async (status) => {
    try {
      const payload = {
        "resource": [
          {
            "id": order.id,
            "status": status,
          }
        ],
      };
      const res = await patchOrder(payload);
      if (res && res.resource[0]) {
        history.push("/Orders");
      }
    } catch (error) {
      setAlertMessage({ type: "danger", message: "Σφάλμα κατά την ενημέρωση της κατάστασης της παραγγελίας." });
    }
  };

  const updateOrder = async (type) => {
    let updatedProducts = products;
    let updatedTotals = totals;
  
    // Check if the type is "red" and apply VAT logic accordingly
    if (type === "red") {
      console.log("REDDDD");
      
      // Update VAT for all products to 0
      updatedProducts = products.map(prd => ({
        ...prd,
        vatProducts: 0,  // Ensure VAT is updated for all products
      }));
  
      // Calculate new totals after updating VAT
      updatedTotals = {
        ...totals,
        totalOrder: totals.totalOrder - totals.totalVat, // Recalculate totalOrder without VAT
        totalVat: 0,  // VAT is now 0
      };
  
      // Update state with new products and totals
      setProducts(updatedProducts);
      setTotals(updatedTotals);
    }
  
    // Create payload based on the updated products and totals
    const payload = {
      resource: [
        {
          id: order.id,
          status: "INVOICED",
          refcode: type === "red" ? "71.00.00" : "71.00.01",  // Use refcode based on type
          products: JSON.stringify(updatedProducts),  // Use updated products if "red"
          totals: JSON.stringify(updatedTotals),  // Use updated totals if "red"
          invoicedAt: type,  // Set invoicedAt to type
        }
      ],
    };

    
  //  console.log(payload)
//    Send the patch request
    const res = await patchOrder(payload);
  
    // Handle response and navigation
    if (res && res.resource[0]) {
      setShipModal(false);
      history.push("/Orders");
    }
  };

  const createTask = async (stk) => {
    try {
      const requirements = JSON.parse(stk.product.requirements);
      if (!requirements.length) {
        setTaskInfo("Το προϊόν δεν έχει βήματα. Επικοινωνήστε με την ομάδα υποστήριξης.");
        return;
      }

      setTaskInfo("Βήματα προϊόντος βρέθηκαν. Παρακαλώ περιμένετε.");

      const results = [];
      for (const task of requirements) {
        const payload = {
          "resource": [
            {
              "type": task.typid,
              "materials": JSON.stringify(task.materials),
              "customer_name": customer.name,
              "status": "PENDING",
              "orderref": order.id,
              "productid": stk.product.id,
              "name": stk.product.name,
              "description": stk.product.description,
              "quantity": parseInt(stk.quantity, 10),
              "expectedDate": order.expected_delivery_date,
              "customer_id_ref": customer.id,
              "seq": task.seq,
            }
          ],
        };

        const res = await postTask(payload);
        if (res && res.resource) {
          results.push(res.resource);
        }
      }

      const tasks = await getTasks()
      setAllTasks(tasks)
      setTaskInfo("Δημιουργήθηκαν οι εργασίες παραγωγής.");
      return results;
    } catch (error) {
      setTaskInfo("Σφάλμα κατά τη δημιουργία των εργασιών παραγωγής.");
    }
  };

  const sendTo = async (type) => {
    try {
      const productStockUpdates = products.map(async prd => {
        const stock = (await getProducts(prd.id))[0].stock;
        return {
          id: prd.id,
          stock: stock - parseInt(prd.quantity, 10),
        };
      });

      const updatedProducts = await Promise.all(productStockUpdates);

      const updateProductPromises = updatedProducts.map(async updatedProduct => {
        const payload = {
          "resource": [updatedProduct],
        };
        const res = await patchProduct(payload);
        if (res.error) {
          console.log(res);
        }
      });

      await Promise.all(updateProductPromises);
      await updateOrder(type);

      const loanPromises = loanProducts.map(async loanPrd => {
        const payload = {
          "resource": [
            {
              "bound": "OUTBOUND",
              "custname": customer.name,
              "quantity": loanPrd.quantity,
              "customer_id": customer.id,
              "order_id": order.id,
              "prd_id": loanPrd.id,
              "prdname": loanPrd.name,
            }
          ],
        };

        const res = await postLoan(payload);
        if (res.error) {
          console.log(res);
        }

        return res;
      });

      await Promise.all(loanPromises);
      history.push("/Orders");
    } catch (error) {
      setAlertMessage({ type: "danger", message: "Σφάλμα κατά την αποστολή παραγγελίας." });
    }
  };

  const getType = () => {
    return order.refcode !== "71.00.00";
  };

  document.title = "Λεπτομέρειες Παραγγελίας | kerino";

  return (
    <React.Fragment>
      {alertMessage.message && <Alert color={alertMessage.type}>{alertMessage.message}</Alert>}
      {taskInfo && <Alert color="info">{taskInfo}</Alert>}
      {!customer ? history.push("/Orders") :
        <div className="page-content" style={{ backgroundColor: getType() ? "#CDEFA4" : "#DBDBDB" }}>
          <ShipModal
            sendToReds={() => sendTo("red")}
            setModal={() => setShipModal(false)}
            modalClose={shipModal}
            sendToEpsilon={() => insertDocuments(order, customer).then(() => sendTo("epsilon"))}
          />

          <Container fluid>
            <Breadcrumb title="Παραγγελίες" breadcrumbItem="Λεπτομέρειες Παραγγελίας" />
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-shrink-0">
                        <img
                          src={avatar}
                          alt=""
                          className="avatar-sm rounded-circle img-thumbnail"
                        />
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <h5 className="mb-0">
                          Κατάσταση: {order.status}
                        </h5>
                        <p className="mb-0">Αναγνωριστικό Παραγγελίας: {order.id}</p>
                        <p className="mb-0">Δημιουργία: {order.created}</p>
                        <p className="mb-0">Αναμενόμενη Ημερομηνία Παράδοσης: {order.expected_delivery_date}</p>
                        <p className="mb-0">Τελευταία Ενημέρωση: {order.updated}</p>

                        {order.status === "INVOICED" && <InvoiceModal customer={customer} order={order} />}
                        {order.status === "ON SHIPPING" && <Button onClick={() => updateOrderStatus("SHIPPED")}>ΟΛΟΚΛΗΡΩΣΗ ΠΑΡΑΓΓΕΛΙΑΣ</Button>}
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Card>
              <CardBody>
                {customer.extra_address && JSON.parse(customer.extra_address).map((add, i) => (
                  <div key={i}>{add.address}</div>
                ))}
              </CardBody>
            </Card>

            <Card>
              {order.status !== "INVOICED" && order.status !== "SHIPPED" && order.status !== "ON SHIPPING" &&
                <CardBody>
                  <div>ΚΑΤΑΣΤΑΣΗ ΑΠΟΘΕΜΑΤΩΝ</div>
                  {stockStatus.map((stk, i) => (
                    <div style={{ margin: 10, color: "red" }} key={i}>
                      {console.log(orderTasks)}
                      {!orderTasks.some(task => task.name === stk.product.name) &&
                        <div>
                          {taskInfo}
                          <Button
                            onClick={() => createTask(stk)}
                            style={{ marginRight: 10 }}>
                            Δημιουργία Εργασίας Παραγωγής για {stk.product.name}
                          </Button>
                          <div>
                            Το προϊόν: {stk.product.name}/{stk.product.sku} έχει μόνο {stk.product.stock} είδη σε απόθεμα ενώ χρειάζεστε {stk.quantity}. ΧΡΕΙΑΖΕΣΤΕ ΠΕΡΙΣΣΟΤΕΡΑ: {stk.quantity - stk.product.stock}
                          </div>
                        </div>
                      }
                    </div>
                  ))}

                  {!stockStatus.length && products.length > 0 && <div style={{ color: "green" }}>
                    ΟΛΑ τα προϊόντα είναι διαθέσιμα σε απόθεμα
                    <Button onClick={() => setShipModal(true)} style={{ marginLeft: 10 }}>ΤΙΜΟΛΟΓΗΣΗ - ΑΠΟΣΤΟΛΗ</Button>
                    <Button style={{ marginLeft: 10 }}>ΠΑΡΚΑΡΙΣΜΑ</Button>
                    <Button style={{ marginLeft: 10 }}>ΑΠΟΡΡΙΨΗ</Button>
                  </div>}

                  {stockStatus.length > 0 && orderTasks.length > 0 && <div style={{ color: "red" }}>
                    Η ΠΑΡΑΓΓΕΛΙΑ ΕΧΕΙ ΕΚΚΡΕΜΕΙΣ ΕΡΓΑΣΙΕΣ ΣΤΗΝ ΠΑΡΑΓΩΓΗ. ΟΤΑΝ ΤΑ ΠΡΟΪΟΝΤΑ ΕΙΝΑΙ ΔΙΑΘΕΣΙΜΑ ΣΕ ΑΠΟΘΕΜΑ, ΑΥΤΗ Η ΟΘΟΝΗ ΘΑ ΑΠΟΜΑΚΡΥΝΘΕΙ.
                  </div>}
                </CardBody>}
            </Card>

            <h4 className="card-title mb-4">Προϊόντα Παραγγελίας</h4>
            <Card>
              <CardBody>
                <Label className="form-label">Ανάλυση Προϊόντων Παραγγελίας</Label>
                <Table className="align-middle mb-0">
                  <thead>
                    <tr>
                      <th>ΑΡΧΕΙΟ</th>
                      <th>ΟΝΟΜΑ</th>
                      <th>SKU</th>
                      <th>ΤΙΜΗ</th>
                      <th>ΦΠΑ Προϊόντος</th>
                      <th>ΠΟΣΟΤΗΤΑ</th>
                      <th>ΣΥΝΟΛΟ</th>
                      <th>ΦΠΑ Παραγγελίας</th>
                      <th>Συνολική Τιμή (με ΦΠΑ)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {products.map((prds, i) => (
                      <tr key={i}>
                        <th scope="row">{i + 1}</th>
                        <td>{prds.name}</td>
                        <td>{prds.sku}</td>
                        <td>{Number(prds.salesprice).toFixed(2)}</td>
                        <td>{Number(prds.vat).toFixed(2)}%</td>
                        <td>{Number(prds.quantity).toFixed(2)}</td>
                        <td>{Number(prds.totalPrdAmmount).toFixed(2)}</td>
                        <td><h5>{Number(prds.vatProducts).toFixed(2)}</h5></td>
                        <td><h5>{(Number(prds.totalPrdAmmount) + Number(prds.vatProducts)).toFixed(2)}</h5></td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <Row>
                  <Card>
                    <CardBody>
                      <h4 className="card-title">
                        Αντικείμενα Παραγγελίας: {products.length} ---  Ποσότητα: {totals.totalItems} -Συνολικό VAT  {Number(totals.totalVat).toFixed(2)} -- Συνολικό Ποσό : {Number(totals.totalOrder).toFixed(2)}
                      </h4>
                    </CardBody>
                  </Card>
                </Row>
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <Row>
                  <Card>
                    <CardBody>
                      <Label className="form-label">Δανεισμένα Προϊόντα</Label>
                      <Table className="align-middle mb-0">
                        <thead>
                          <tr>
                            <th>SKU</th>
                            <th>ΟΝΟΜΑ</th>
                            <th>ΠΟΣΟΤΗΤΑ</th>
                          </tr>
                        </thead>
                        <tbody>
                          {loanProducts.map((prds, i) => (
                            <tr key={i}>
                              <td>{prds.sku}</td>
                              <td>{prds.name}</td>
                              <td>{prds.quantity}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </CardBody>
                  </Card>
                </Row>
              </CardBody>
            </Card>
          </Container>
        </div>
      }
    </React.Fragment>
  );
};

OrderDetail.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  error: PropTypes.string,
  success: PropTypes.string,
};

const mapStateToProps = state => ({
  error: state.Profile.error,
  success: state.Profile.success,
});

export default connect(mapStateToProps)(OrderDetail);
