
import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Label,

} from "reactstrap";
import { isEmpty, map, size } from "lodash"
import PropTypes from "prop-types"
//redux
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";
import avatar from "../../assets/images/users/avatar-1.jpg";
import { toNumber } from "lodash";


const sumArray = (array, type) => {
  const sumall = array.map(item => item[type] && toNumber(item[type])).reduce((prev, curr) => prev + curr, 0);
  return sumall
}


const InvoiceDetail = (props) => {

  //meta title
  document.title = "Profile | kerino - React Admin & Dashboard Template";
  const dispatch = useDispatch();
  const [provider, setprovider] = useState("Select Provider");
  const [totals, settotals] = useState({})
  const [products, setproducts] = useState([]);
  const { error, success } = useSelector(state => ({
    error: state.Profile.error,
    success: state.Profile.success,
  }));



  useEffect(() => {
    const totalPrdAmmount = sumArray(products, "totalPrdAmmount")
    const totalVat = sumArray(products, "vatProducts")
    const totalQuantity = sumArray(products, "quantity")
    settotals({ totalPrdAmmount: totalPrdAmmount, totalVat: totalVat, totalQuantity: totalQuantity })

  }, [products]);

  useEffect(() => {
    //console.log(props.location.state)
    setproducts(JSON.parse(props.location.state.content))
    setprovider(props.location.state.providerDetails)
  }, [])



  const getType = () => {
    const refcode = props.location.state.refcode
    if (refcode == "60.00.00" ||  refcode == "71.00.00" || refcode == "24.00.00" || refcode == "64.00.00") {
      return false
    }
    else {
      return true
    }
  }

  return (
    <React.Fragment>
      <div className="page-content" style={{backgroundColor: getType()? "#CDEFA4":"#DBDBDB"}}>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="kerino" breadcrumbItem="Invoice Analysis" />
          <Row>
            {totals && totals.totalPrdAmmount && <Col>Items:{totals.totalQuantity} Total ammount: {totals.totalPrdAmmount}  Vat : {totals.totalVat} </Col>}
          </Row>
          <Row>
            <Col lg="12">


              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="ms-3">
                      <img
                        src={avatar}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <h5>{provider.name}</h5>
                        <p className="mb-1">{provider.phone}</p>
                        <p className="mb-0">Number : {props.location.state.number}</p>
                        <p className="mb-0">ImportDate : {props.location.state.importDate}</p>
                        <p className="mb-0">Created : {props.location.state.created}</p>
                        <p className="mb-0">Insert By user : {props.location.state.enteredby}</p>
                        <p className="mb-0">RefCode : {props.location.state.refcode}</p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>


          <Card>
            <CardBody>
              <Label className="form-label">Invoice items</Label>

              <table className="table table-nowrap align-middle mb-0">
                <thead>
                  <tr>
                    <th>ITEM</th>
                    <th>NAME</th>
                    <th>Old PRICE</th>
                    <th>Latest PRICE</th>
                    <th>QUANTITY</th>
                    <th>VAT</th>
                    <th>TOTAL</th>
                  </tr>
                </thead>
                <tbody>
                  {map(products, (item, i) => (
                    <tr key={i}>
                      <td style={{ width: "40px" }}>
                        <div className="form-check font-size-16">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id={item.id}
                          />
                        </div>
                      </td>
                      <td>
                        <h5 className="text-truncate font-size-14 m-0">
                          {item.name}
                        </h5>
                      </td>
                      <td>

                        <h5 className="text-truncate font-size-14 m-0">
                          {item.costprice}
                        </h5>
                      </td>
                      <td>

                        <h5 className="text-truncate font-size-14 m-0">
                          {item.costpricenew}
                        </h5>
                      </td>
                      <td>
                        <h5 className="text-truncate font-size-14 m-0">
                          {item.quantity}
                        </h5>
                      </td>
                      <td>

                        <h5 className="text-truncate font-size-14 m-0">
                          {item.vatProducts}
                        </h5>
                      </td>

                      <td>
                        <h5 className="text-truncate font-size-14 m-0">
                          {item.totalPrdAmmount}
                        </h5>
                      </td>

                    </tr>
                  ))}
                </tbody>
              </table>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

InvoiceDetail.propTypes = {

  location: PropTypes.object,
}

export default withRouter(InvoiceDetail);
