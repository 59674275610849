import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row, Modal, ModalHeader, ModalBody } from "reactstrap";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { getLoans } from "helpers/api_helper";
import ReactApexChart from "react-apexcharts"; // Import ReactApexChart
import CustomerDetails from "./customerDetails";
import ProductDetails from "./prdDetails";
import CustomerSelect from "../Orders/customersmodal";
import { useSelector, useDispatch } from "react-redux"
import LoanModal from "./loanreturnmodal";

const Loans = (props) => {
  //meta title
  document.title = "Στατιστικά Δανείων";

  const [loans, setLoans] = useState([]);
  const [loanmodal, setloanmodal] = useState(false);
  const [viewType, setViewType] = useState("totals"); // Initialize view type to "totals"
  const [customerss, setCustomerss] = useState([]);

  // Function to fetch loans and transform data
  const fetchLoansData = () => {
    getLoans().then((res) => {
      setLoans(res);
      setCustomerss(transformLoansData(res));
    });
  };

  // Use useEffect to load data on component mount
  useEffect(() => {
    fetchLoansData();
  }, []);

  const transformLoansData = (loans) => {
    const customerMap = {};
    loans.forEach((loan) => {
      const { customer_id, custname, prdname, quantity, bound } = loan;

      if (!customerMap[customer_id]) {
        customerMap[customer_id] = {
          customerid: customer_id,
          customername: custname,
          custloans: {},
        };
      }

      const customerLoans = customerMap[customer_id].custloans;

      if (!customerLoans[prdname]) {
        customerLoans[prdname] = 0;
      }

      // Adjust the total based on INBOUND or OUTBOUND
      if (bound === 'INBOUND') {
        customerLoans[prdname] -= quantity;
      } else if (bound === 'OUTBOUND') {
        customerLoans[prdname] += quantity;
      }
    });

    // Convert the customerMap into an array of customers with the desired structure
    return Object.values(customerMap).map((customer) => {
      const custloansArray = Object.keys(customer.custloans).map((productname) => ({
        productname,
        total: customer.custloans[productname],
      }));

      return {
        customerid: customer.customerid,
        customername: customer.customername,
        custloans: custloansArray,
      };
    });
  };

  // Process the data to calculate the total quantity per customer

  const { customers } = useSelector(state => ({
    customers: state.customers.customers
  }))

  // Handle view type change
  const handleViewTypeChange = (event) => {
    setViewType(event.target.value);
  };

  const calculateTotalQuantity = (data) => {
    return data.reduce((total, item) => {
      if (item.bound === 'INBOUND') {
        return total - item.quantity;
      } else if (item.bound === 'OUTBOUND') {
        return total + item.quantity;
      }
      return total; // In case bound is neither INBOUND nor OUTBOUND
    }, 0);
  };

  const totalQuantity = calculateTotalQuantity(loans);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container>
          <Row>
            <Button onClick={() => setloanmodal(true)}>Προσθήκη Εισερχόμενου Δανείου</Button>
          </Row>

          <LoanModal fetchLoansData={fetchLoansData} loans={loans} customers={customers} setLoanModal={setloanmodal} isOpen={loanmodal}>
          </LoanModal>

          <Row>
            <Col>
              <h4>Συνολικά Δάνεια: {totalQuantity}</h4>
            </Col>
          </Row>
          <Row>
            <ProductDetails loans={loans}></ProductDetails>
          </Row>

          <Row>
            <CustomerDetails loans={loans}></CustomerDetails>
          </Row>

        </Container>
      </div>
    </React.Fragment>
  );
};

Loans.propTypes = {
  invoices: PropTypes.array,
  onGetInvoices: PropTypes.func,
  customers: PropTypes.array,
};

export default withRouter(Loans);
