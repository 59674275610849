import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import PropTypes from "prop-types";

const ProductDetails = ({ loans }) => {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [customerData, setCustomerData] = useState([]);

  // Calculate inbound, outbound, and total quantities per product
  const productData = loans.reduce((acc, loan) => {
    const productName = loan.prdname;
    const quantity = loan.quantity;
    const bound = loan.bound;

    if (!acc[productName]) {
      acc[productName] = { inbound: 0, outbound: 0 };
    }

    if (bound === "INBOUND") {
      acc[productName].inbound += quantity;
    } else if (bound === "OUTBOUND") {
      acc[productName].outbound += quantity;
    }

    return acc;
  }, {});

  // Convert product data into chart data
  const chartData = Object.keys(productData).map((productName) => {
    const inbound = productData[productName].inbound;
    const outbound = productData[productName].outbound;
    const total = outbound - inbound;
    return { productName, inbound, outbound, total };
  });

  const chartOptions = {
    chart: {
      id: "bar-chart",
      events: {
        dataPointSelection: (event, chartContext, config) => {
          const selectedProductName = chartData[config.dataPointIndex].productName;
          setSelectedProduct(selectedProductName);

          // Calculate customer totals for the selected product, considering INBOUND and OUTBOUND
          const customerTotals = loans
            .filter((loan) => loan.prdname === selectedProductName)
            .reduce((acc, loan) => {
              const customerName = loan.custname;
              const quantity = loan.quantity;
              const bound = loan.bound;

              if (!acc[customerName]) {
                acc[customerName] = 0;
              }

              // Subtract INBOUND, Add OUTBOUND
              if (bound === "INBOUND") {
                acc[customerName] -= quantity;
              } else if (bound === "OUTBOUND") {
                acc[customerName] += quantity;
              }

              return acc;
            }, {});

          // Convert to array format and filter out customers with a total of 0
          const customerList = Object.keys(customerTotals)
            .map((custname) => ({
              custname,
              total: customerTotals[custname],
            }))
            .filter((customer) => customer.total !== 0); // Filter out customers with a total of 0

          setCustomerData(customerList);
        },
      },
    },
    xaxis: {
      categories: chartData.map((data) => data.productName),
    },
  };

  const chartSeries = [
    {
      name: "Net Quantity",
      data: chartData.map((data) => data.total),
    },
  ];

  return (
    <div>
      <h4>ΣΥΝΟΛΟ Ανά Προϊόν</h4>
      <div>
        <ReactApexChart
          options={chartOptions}
          series={chartSeries}
          type="bar"
          height="400"
        />
      </div>

      {/* Display customer list for the selected product */}
      {selectedProduct && (
        <div>
          <h3>{selectedProduct} ανά Πελάτη</h3>
          <ul>
            {customerData.map((customer, index) => (
              <li key={index}>
                <strong>{customer.custname}:</strong> {customer.total}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

ProductDetails.propTypes = {
  loans: PropTypes.array,
};

export default ProductDetails;
