import React, { useState } from "react"
import PropTypes from "prop-types"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Input,
  FormGroup,
  Label,
  Button,
} from "reactstrap"


export const CatCustomer = (props) => (
<Input value={props.category} onChange={(e) => props.setCategory(e.target.value)} type="select" name="select" >
<option value={"Category1"}>Category 1</option> 
<option value={"Category2"}>Category 2</option> 
</Input>)

CatCustomer.propTypes = {
   category: PropTypes.any,
   setCategory: PropTypes.func,
  }
  

  export const CatUnits = (props) => (
    <Input value={props.unit} onChange={(e) => props.setunit(e.target.value)} type="select" name="select" id="exampleSelect">
    <option value={"KG"}>Killos</option> 
    <option value={"Meter"}>Meter</option> 
    <option value={"CM"}>Centimeters</option> 
    <option value={"MM"}>Milimmetes</option> 
    <option value={"Litter"}>Litter</option> 
    <option value={"CubicM"}>CubicM</option>
    <option value={"Item"}>Item</option>

    </Input>)
    
    CatUnits.propTypes = {
       unit: PropTypes.object,
       setunit: PropTypes.func,
      }


      export const CatVat = (props) => (
        <Input value={props.catVat} onChange={(e) => props.setCatVat(e.target.value)} type="select" name="select" id="exampleSelect">
        <option value={"0"}>0</option> 
        <option value={"24"}>24%</option> 

    
        </Input>)
        
        CatVat.propTypes = {
           catVat: PropTypes.any,
           setCatVat: PropTypes.func,
          }