import React, { useMemo } from "react";
import PropTypes from 'prop-types';

// Import components
import Breadcrumbs from '../../components/Common/Breadcrumb';
import TableContainer from '../../components/Common/TableContainer';

function OrdersTable(props) {

    // Parse the `Totals` field and add `totalOrder` to each order object
    const data = props.orders.map(order => {
        const parsedTotals = JSON.parse(order.totals || '{}');
        return {
            ...order,
            totalOrder: parsedTotals.totalOrder || 0, // Default to 0 if `totalOrder` is missing
            totalVat: parsedTotals.totalVat || 0,
            totalProductsAmmount: parsedTotals.totalProductsAmmount || 0,
            totalItems: parsedTotals.totalItems || 0
        };
    });

    const columns = useMemo(
        () => [
            {
                Header: 'Ημερομηνία Δημιουργίας', // Translated header
                accessor: 'created',
            },
            {
                Header: 'Αναγνωριστικό', // Translated header
                accessor: 'id',
            },
            {
                Header: 'Όνομα', // Translated header
                accessor: 'name',
            },
            {
                Header: 'Ημερομηνία Παράδοσης', // Translated header
                accessor: 'expected_delivery_date',
            },
            {
                Header: 'Κατάσταση', // Translated header
                accessor: 'status',
            },
            {
                Header: 'Συνολικά Αντικείμενα', // Translated header for `totalItems`
                accessor: 'totalItems',
            },
            {
                Header: 'Σύνολο Προϊόντων', // Translated header for `totalProductsAmmount`
                accessor: 'totalProductsAmmount',
            },
            {
                Header: 'ΦΠΑ', // Translated header for `totalVat`
                accessor: 'totalVat',
            },
            {
                Header: 'Συνολικό Ποσό', // Translated header for `totalOrder`
                accessor: 'totalOrder',
            },
            {
                Header: 'Ημερομηνία Τιμολόγησης', // Translated header for `invoicedAt`
                accessor: 'invoicedAt',
            },
        ],
        []
    );

    document.title = "Πίνακες Δεδομένων | kerino - React Admin & Dashboard Template";

    return (
        <TableContainer
            rowClick={"/order-detail"}
            columns={columns}
            data={data}
            isGlobalFilter={false}
            isAddOptions={false}
            customPageSize={150}
            className="custom-header-css"
        />
    );
}

OrdersTable.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
    orders: PropTypes.array.isRequired
};

export default OrdersTable;
