import React, { useState } from 'react';
import AWS from 'aws-sdk';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { getCustomers, getOrders, getProducts, getInvoices, getLoans, postCustomer, postOrder, postProduct, postInvoice, postLoan } from "helpers/api_helper"; // Your API helpers
import { Container, Row, Col, Card, CardBody, Button, Input, Spinner } from 'reactstrap';

// Configure AWS credentials and S3 instance
AWS.config.update({
    accessKeyId: 'AKIA2Z4LN5KU5MCLXIBX',
    secretAccessKey: '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',
  });

const s3 = new AWS.S3();

const SettingsPage = () => {
  const [backupInProgress, setBackupInProgress] = useState(false);
  const [restoreInProgress, setRestoreInProgress] = useState(false);

  // Backup data to local zip file
  const handleBackup = async () => {
    setBackupInProgress(true);
    try {
      const customers = await getCustomers();
      const orders = await getOrders();
      const products = await getProducts();
      const invoices = await getInvoices();
      const loans = await getLoans();

      const zip = new JSZip();
      zip.file('customers.json', JSON.stringify(customers, null, 2));
      zip.file('orders.json', JSON.stringify(orders, null, 2));
      zip.file('products.json', JSON.stringify(products, null, 2));
      zip.file('invoices.json', JSON.stringify(invoices, null, 2));
      zip.file('loans.json', JSON.stringify(loans, null, 2)); // Add loans to the backup

      const content = await zip.generateAsync({ type: 'blob' });
      saveAs(content, `backup-${new Date().toISOString().slice(0, 10)}.zip`);
    } catch (error) {
      console.error('Error during backup:', error);
    }
    setBackupInProgress(false);
  };

  // Backup data to S3
  const handleBackupToS3 = async () => {
    setBackupInProgress(true);
    try {
      const customers = await getCustomers();
      const orders = await getOrders();
      const products = await getProducts();
      const invoices = await getInvoices();
      const loans = await getLoans();

      const zip = new JSZip();
      zip.file('customers.json', JSON.stringify(customers, null, 2));
      zip.file('orders.json', JSON.stringify(orders, null, 2));
      zip.file('products.json', JSON.stringify(products, null, 2));
      zip.file('invoices.json', JSON.stringify(invoices, null, 2));
      zip.file('loans.json', JSON.stringify(loans, null, 2)); // Add loans to the backup

      const content = await zip.generateAsync({ type: 'blob' });

      const params = {
        Bucket: 'kerinobuckup',
        Key: `backup-${new Date().toISOString().slice(0, 10)}.zip`,
        Body: content,
        ContentType: 'application/zip'
      };

      // Upload the zipped backup to S3
      s3.upload(params, (err, data) => {
        if (err) {
          console.error('Error uploading to S3:', err);
        } else {
          console.log('Backup successfully uploaded to S3:', data.Location);
        }
      });
    } catch (error) {
      console.error('Error during backup to S3:', error);
    }
    setBackupInProgress(false);
  };

  // Import functionality
  const handleImport = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setRestoreInProgress(true);
    try {
      const zip = new JSZip();
      const content = await zip.loadAsync(file);

      // Read each JSON file from the zip and call the respective API
      if (content.files['customers.json']) {
        const customers = await content.files['customers.json'].async('string');
        const customerList = JSON.parse(customers);
        await Promise.all(customerList.map(customer => postCustomer({ resource: [customer] })));
      }

      if (content.files['orders.json']) {
        const orders = await content.files['orders.json'].async('string');
        const orderList = JSON.parse(orders);
        await Promise.all(orderList.map(order => postOrder({ resource: [order] })));
      }

      if (content.files['products.json']) {
        const products = await content.files['products.json'].async('string');
        const productList = JSON.parse(products);
        await Promise.all(productList.map(product => postProduct({ resource: [product] })));
      }

      if (content.files['invoices.json']) {
        const invoices = await content.files['invoices.json'].async('string');
        const invoiceList = JSON.parse(invoices);
        await Promise.all(invoiceList.map(invoice => postInvoice({ resource: [invoice] })));
      }

      if (content.files['loans.json']) {
        const loans = await content.files['loans.json'].async('string');
        const loanList = JSON.parse(loans);
        await Promise.all(loanList.map(loan => postLoan({ resource: [loan] }))); // Import loans
      }

      console.log('Data imported successfully.');
    } catch (error) {
      console.error('Error during import:', error);
    }
    setRestoreInProgress(false);
  };

  return (
    <div className="page-content">
      <Container fluid>
        <h4 className="mb-4">Backup and Restore</h4>

        <Row>
          {/* Backup to Local */}
          <Col md={4}>
            <Card>
              <CardBody className="text-center">
                <h5>Backup to Local</h5>
                <p>Download a backup of all data as a zip file.</p>
                <Button color="primary" onClick={handleBackup} disabled={backupInProgress}>
                  {backupInProgress ? <Spinner size="sm" /> : 'Backup Now'}
                </Button>
              </CardBody>
            </Card>
          </Col>

          {/* Backup to S3 */}
          <Col md={4}>
            <Card>
              <CardBody className="text-center">
                <h5>Backup to S3</h5>
                <p>Backup all data and upload to your S3 bucket.</p>
                <Button color="success" onClick={handleBackupToS3} disabled={backupInProgress}>
                  {backupInProgress ? <Spinner size="sm" /> : 'Backup to S3'}
                </Button>
              </CardBody>
            </Card>
          </Col>

          {/* Import from Backup */}
          <Col md={4}>
            <Card>
              <CardBody className="text-center">
                <h5>Restore Data</h5>
                <p>Upload a backup file to restore data.</p>
                <Input type="file" onChange={handleImport} disabled={restoreInProgress} />
                {restoreInProgress && <Spinner className="mt-2" />}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SettingsPage;
