import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import {
  Table,
  Input,
  Button

} from "reactstrap"
import "react-datepicker/dist/react-datepicker.css"


const OrderTable = (props) => {
  const { products } = props;
  //  const [products, setproducts] = useState([props.products])
  // const [costprice, setcostprice] = useState()
  const shouldShowButton = (prds) => {
    const lendProduct = JSON.parse(prds.requirements);
    const mats = lendProduct[0].materials;
    const canlend = mats.filter((ppr) => ppr.canlended === true);

    if (canlend[0]) {
      const lendedid = canlend[0].id;
      console.log(lendedid);

      // Check if lendedid is included in Loanproducts
      const alreadylended = props.Loanproducts.some((product) => product.id === lendedid);

      return !alreadylended; // Return false if lendedid is already in Loanproducts
    }

    return true; // Return true if canlend[0] is falsy
  }
  const checkifloans = (prds) => {
    console.log(prds)
    const canlend = prds.canlend
    const lendProduct = JSON.parse(prds.requirements);
  
    // Check if lendProduct has records (i.e., it's not empty)
    if (lendProduct && lendProduct.length > 0) {
      const mats = lendProduct[0].materials;
      const canlend = mats.filter((ppr) => ppr.canlended === true);
      
      if (canlend[0]) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }


  return (
    <>
      <Table className="align-middle mb-0">
        <thead>
          <tr>
            <th>ITEM</th>
            <th>NAME</th>
            <th>SKU</th>
            <th>LCOST</th>
            <th>PRICE</th>
            <th>Product VAT</th>
            <th>UNITS</th>
            <th>QUANTITY</th>
            <th>TOTAL</th>
            <th>VAT</th>
            <th>Inc-VAT</th>
            <th>Stock</th>
            <th>ACTION</th>
            <th>LOAN</th>
            <th>DELETE</th>
          </tr>
        </thead>
        <tbody>
          {props.products && props.products.map((prds, i) => (
            <tr key={i}>
              <th scope="row">{i + 1}</th>
              <td>{prds.name}</td>
              <td>{prds.sku}</td>
              <td style={{ color: prds.costprice > prds.salesprice ? "red" : "green" }}>{prds.costprice}</td>
              <td>
                <Input

                  value={prds.salesprice}
                  type="number"
                  style={{ backgroundColor: prds.costprice > prds.salesprice ? "red" : "#E4FEE4" }}
                  onChange={(e) => {

                    let value = e.target.value
                    let newArr = [...products];
                    newArr[i].salesprice = value;
                    props.setproducts(newArr);

                  }}>
                </Input>
              </td>
              <td>{prds.vat}%</td>
              <td>{prds.unit}</td>
              <td>
                <Input style={{ maxWidth: 100 }} type="number"
                  onChange={(e) => {
                    props.products[i].ammount = e.target.value
                    // //console.log(props.products[i])
                    let value = e.target.value
                    let newArr = [...products];
                    newArr[i].quantity = value;
                    newArr[i].totalPrdAmmount = (Number(value) * Number(prds.salesprice)).toFixed(2);
                    newArr[i].vatProducts = Number(Number(value) * Number(prds.salesprice) * Number(prds.vat / 100)).toFixed(2);
                    props.setproducts(newArr);

                  }}>

                </Input>
              </td>
              <td>{Number(prds.totalPrdAmmount).toFixed(2)}</td>
              <td><h5>{parseFloat(prds.vatProducts).toFixed(2)}</h5></td>
              <td><h5>{(Number(prds.totalPrdAmmount) + Number(prds.vatProducts)).toFixed(2)}</h5></td>
              <td>
                {prds.stock > prds.quantity && <h5 style={{ color: "green" }}>{prds.stock - prds.quantity}</h5>}
                {prds.stock < prds.quantity && <h5 style={{ color: "red" }}>{prds.stock - prds.quantity}</h5>}
              </td>
              <td>
                <button onClick={() => props.checkHistory(prds)} type="button" className="btn btn-light btn-sm">History</button>
              </td>
              <td>

                {prds.quantity > 0 && checkifloans(prds) && shouldShowButton(prds) &&

                  <button onClick={() => {
                    const lendProduct = JSON.parse(prds.requirements);


                    const mats = lendProduct[0].materials;
                    const canlend = mats.filter((ppr) => ppr.canlended === true);
                    if (canlend[0]) {
                      canlend[0].quantity = prds.quantity
                    }



                    const updateProductCostPrice = async (productId) => {
                      try {
                        // Simulate an asynchronous operation to fetch the new cost price
                        const newCostPrice = await props.getProducts(productId); // Replace with your actual fetch operation
                        // Find the product with the matching ID
                        const updatedProducts = products.map((product) => {
                          if (product.id === prds.id) {
                            return { ...product, costprice: prds.costprice - newCostPrice[0].costprice };
                          }
                          return product;
                        });

                        // Update the state with the new array of products
                        props.setproducts(updatedProducts);

                      } catch (error) {
                        console.error('Error fetching new cost price:', error);
                      }
                    };

                    updateProductCostPrice(canlend[0].id)
                    // const updatedLoanProducts = [...props.Loanproducts, canlend[0]];
                    props.setLoanproducts([...props.Loanproducts, canlend[0]]);

                  }} type="button" className="btn btn-light btn-sm">Lend</button>
                }


              </td>
              <td>
                <button onClick={() => props.setproducts(products.filter((item, ind) => ind !== i))} type="button" className="btn btn-light btn-sm">Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

    </>
  )
}


OrderTable.propTypes = {
  products: PropTypes.array,
  Loanproducts: PropTypes.array,
  onGetInvoices: PropTypes.func,
  fixCosts: PropTypes.func,
  history: PropTypes.object,
  setproducts: PropTypes.func,
  setLoanproducts: PropTypes.func,
  getProducts: PropTypes.func,
  checkHistory: PropTypes.func

}

export default withRouter(OrderTable)
