import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Col, Row } from 'reactstrap';
import html2canvas from 'html2canvas';


const InvoiceModal = ({ order, customer }) => {
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    const parsedProducts = JSON.parse(order.products);
    const parsedTotals = JSON.parse(order.totals);



    return (
        <>
            <Button color="primary" onClick={toggle}>
                View Invoice
            </Button>
            <Modal style={{ minWidth: 1000 }} isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>Invoice</ModalHeader>
                <ModalBody id="invoice-modal-content">
                    <div className="mb-4">
                        <strong>Customer:</strong> {customer.label} <br />
                        <strong>Address:</strong> {customer.address}, {customer.city}{' '}
                        {customer.postcode}
                        <br />
                        <strong>VAT:</strong> {customer.vat}
                    </div>
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>SKU</th>
                                <th>Product</th>
                                <th>Qty</th>
                                <th>vat</th>
                                <th>price</th>
                                <th>total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {parsedProducts.map((product) => (
                                <tr key={product.id}>
                                    <td>{product.sku || '-'}</td>
                                    <td>{product.name}</td>
                                    <td>{product.quantity}</td>
                                    <td>{product.vat || '-'}</td>
                                    <td>{product.salesprice || '-'}</td>
                                    <td>{product.totalPrdAmmount || '-'}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
       
                    <Row>
                        <Col>
                            <strong>Total Items:</strong> {parsedTotals.totalItems} <br />
                            <strong>Total Products Amount:</strong>{' '}
                            {parsedTotals.totalProductsAmmount} <br />
                        </Col>
                        <Col>
                            <strong>Total VAT:</strong> {parsedTotals.totalVat} <br />
                            <strong>Total Order:</strong> {parsedTotals.totalOrder}
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
       
                    <Button color="secondary" onClick={toggle}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

InvoiceModal.propTypes = {
    order: PropTypes.object.isRequired,
    customer: PropTypes.object.isRequired,
};

export default InvoiceModal;
