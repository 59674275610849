import React, { useMemo } from "react";
import PropTypes from 'prop-types';

// Import components
import Breadcrumbs from '../../components/Common/Breadcrumb';
import TableContainer from '../../components/Common/TableContainer';

function InvoicesTable(props) {

    // Parse the `Totals` field and add `totalOrder` to each order object
    const data = props.invoices.map(invoice => {
        const parsedTotals = JSON.parse(invoice.totals || '{}');
        return {
            ...invoice,
            totalOrder: parsedTotals.totalOrder || 0, // Default to 0 if `totalOrder` is missing
            totalVat: parsedTotals.totalVat || 0,
            totalProductsAmmount: parsedTotals.totalPrdAmmount || 0,
            totalItems: parsedTotals.totalQuantity || 0
        };
    });

    const columns = useMemo(
        () => [

            {
                Header: 'Created',
                accessor: 'created',
            },
            {
                Header: 'Import Date',
                accessor: 'importDate',
            },
            {
                Header: 'id',
                accessor: 'id',
            },
            {
                Header: 'Name',
                accessor: 'providername',
            },

          
            {
                Header: 'Items',  // New column for `totalOrder`
                accessor: 'totalItems',
            },

            {
                Header: 'Total',  // New column for `totalOrder`
                accessor: 'totalProductsAmmount',
            },
            {
                Header: 'Vat',  // New column for `totalOrder`
                accessor: 'totalVat',
            },
            
            {
                Header: 'Total Order',  // New column for `totalOrder`
                accessor: 'totalOrder',
            },
            {
                Header: 'Tagged',  // New column for `totalOrder`
                accessor: 'refcode',
            },
        ],
        []
    );

    document.title = "Data Tables | kerino - React Admin & Dashboard Template";

    return (
        <TableContainer
            rowClick={"/invoice-detail"}
            columns={columns}
            data={data}
            isGlobalFilter={false}
            isAddOptions={false}
            customPageSize={150}
            className="custom-header-css"
        />
    );
}

InvoicesTable.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
    invoices: PropTypes.any
};

export default InvoicesTable;
