import React, { useState, useRef, useLayoutEffect, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import GoogleMapReact from 'google-map-react';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';

const MapModal = (props) => {

  const [selectedAddress, setSelectedAddress] = useState(props.address);
  const [modalOpen, setModalOpen] = useState(false);
  const [center, setCenter] = useState({ lat: 37.9838, lng: 23.7275 });
  const [searchResults, setSearchResults] = useState([]);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const searchInputRef = useRef();

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  // useEffect(() => {
  //   if (props.address) {
  //   centers(props.address)
  //   }
  // }, [props.address]);

  // useEffect(() => {
  //   if (props.address) {
  //     const handleGeocode = async (address) => {
  //       try {

  //         setTimeout(async () => {
  //           centers(props.address)
  //         }, 2000); // Adjust the delay time as needed
  //       } catch (error) {
  //         console.error("Geocoding error:", error);
  //       }
  //     };
  
  //     handleGeocode(props.address);
  //   }
  // }, [props.address]);

  console.log(selectedMarker)

  const handleMapClick = (event) => {
    const { lat, lng } = event;
    setSelectedMarker({ lat, lng });
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: { lat, lng } }, (results, status) => {
      if (status === 'OK' && results[0]) {
        const address = results[0].formatted_address;
        setSelectedAddress(address);
        setCenter({ lat, lng });
      }
    });
  };

  const handleSearch = () => {
    const searchQuery = searchInputRef.current.value;
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address: searchQuery }, (results, status) => {
      if (status === 'OK') {
        setSearchResults(results);
      }
    });
  };

  const handleSelect = async (address) => {
    try {
      const results = await geocodeByAddress(address.formatted_address);
      const selected = results[0];
      setSelectedAddress(selected.formatted_address);
      setCenter(selected.geometry.location.toJSON());
      setSearchResults([]);
      setSelectedMarker(selected.geometry.location.toJSON());
    } catch (error) {
      console.error(error);
    }
  };

  const centers = (address) =>{ 
  
      geocodeByAddress(address).then(res => {
      setCenter(res[0].geometry.location.toJSON());
      setSearchResults([]);
      setSelectedMarker(res[0].geometry.location.toJSON());
    })
  }

  return (
    <div>
      <Button color="primary" onClick={toggleModal}>
        Map
      </Button>

      <Modal isOpen={modalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Select a location on the map</ModalHeader>
        <ModalBody>
          <div style={{ height: '400px', width: '100%', position: 'relative' }}>
            <GoogleMapReact
              bootstrapURLKeys={{
                key: 'AIzaSyCgSYLTDgQLJ_Bn37O_kcF9uIMamtIakP8',
              }}
              defaultCenter={{ lat: 37.9838, lng: 23.7275 }}
              center={center}
              defaultZoom={13}
              onClick={handleMapClick}
            >
              {selectedMarker && (
                <div
                  key="selectedMarker"
                  style={{
                    width: '20px',
                    height: '20px',
                    backgroundColor: 'red',
                    borderRadius: '50%',
                    position: 'absolute',
                    left: `${selectedMarker.lng - 10}px`,
                    top: `${selectedMarker.lat - 10}px`,
                  }}
                />
              )}
            </GoogleMapReact>
          </div>
          <div>
            <input type="text" ref={searchInputRef} placeholder="Search for an address" />
            <button onClick={handleSearch}>Search</button>
            {searchResults.length > 0 && (
              <ul>
                {searchResults.map((result) => (
                  <li key={result.place_id} onClick={() => handleSelect(result)}>
                    {result.formatted_address}
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div>{selectedAddress}</div>
          <button onClick={() => centers(props.address)}>Center</button>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModal}>
            Cancel
          </Button>
          <Button
            color="secondary"
            onClick={() => {
              props.onAddressSelect(selectedAddress);
              toggleModal(false);
            }}
          >
            Save
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

MapModal.propTypes = {
  onAddressSelect: PropTypes.func.isRequired,
  address: PropTypes.any
};

export default MapModal;
