import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Input } from 'reactstrap';
import ReactDrawer from 'react-drawer';

const MyDrawer = ({ open, position, onClose, style, filteredMaterials, selectedStep,setSearch }) => {


    const [hoveredRowIndex, setHoveredRowIndex] = useState(null);

    const fixColorsByType = (type) => {
        // Fix color logic here
    };

    return (
        <ReactDrawer
            open={open}
            position={position}
            onClose={onClose}
            style={style}
        >
            <Row style={{ margin: 10 }}>
                <Input onChange={(e) => setSearch(e.target.value)} placeholder="Search"></Input>
            </Row>
            <div style={{ margin: 10, maxHeight: '90%', overflowY: 'auto' }}>
                <Row style={{ margin: 10, backgroundColor: '#f9f9c4', borderTop: '2px solid #ddd' }}>
                    <Col lg="6">Description</Col>
                    <Col lg="3">Stock</Col>
                    <Col lg="3">Units</Col>
                </Row>
                {filteredMaterials && filteredMaterials.map((prd, i) => {
                    return (
                        <Row
                            key={i}
                            onMouseEnter={() => setHoveredRowIndex(i)}
                            onMouseLeave={() => setHoveredRowIndex(null)}
                            onClick={() => {
                           
                                selectedStep(prd)
                                onClose(false)
                            }}
                            style={{
                                backgroundColor: fixColorsByType(prd.usageType),
                                fontWeight: hoveredRowIndex === i ? 'bold' : 18,
                                fontSize: hoveredRowIndex === i ? 'larger' : 12
                            }}
                            id={`row${i}`}
                        >
                            <Col lg="6">{prd.usageType === "MATERIAL" && <i style={{ marginRight: 4 }} className="bx bx-brush" />}
                                {prd.usageType === "PURCHASED-MATERIAL" && <i style={{ marginRight: 4 }} className="bx bxs-chip" />}
                                {prd.usageType === "COMPLETE-PRODUCT" && <i style={{ marginRight: 4 }} className="bx bxl-digitalocean" />}
                                {prd.name}</Col>
                                <Col lg="3">{prd.stock}</Col>
                            <Col lg="3">{prd.unit}</Col>
                        </Row>
                    );
                })}
            </div>
        </ReactDrawer>
    );
}

MyDrawer.propTypes = {
    open: PropTypes.bool.isRequired,
    position: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    style: PropTypes.object,
    filteredMaterials: PropTypes.array.isRequired,
    selectedStep: PropTypes.func.isRequired,
    setSearch:PropTypes.func,

};

export default MyDrawer;
