import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Label, Input ,Col,Row} from 'reactstrap';
import PropTypes from 'prop-types';
import MapModal from './mapAddress';

const AddressModal = ({ addresses, onSave }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [newAddress, setNewAddress] = useState({
        address: '',
        postcode: '',
        phone: '',
        city: '',
        building: '',
        floor: '',
    });
    const [addressList, setAddressList] = useState(addresses);
    const [showForm, setShowForm] = useState(false);

    useEffect(() => {
        setAddressList(addresses);
    }, [addresses]);

    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };

    const toggleForm = () => {
        setShowForm(!showForm);
    };

    const handleAddAddress = () => {
        const updatedAddresses = [...addressList, newAddress];
        setAddressList(updatedAddresses);
        setNewAddress({
            address: '',
            postcode: '',
            phone: '',
            city: '',
            building: '',
            floor: '',
        });
        setShowForm(false); // Close the form after adding address
    };
    const handleSave = () => {
        onSave(addressList);
        toggleModal();
    };

    return (
        <div>
            <Button color="primary" onClick={toggleModal}>Extra Addresses</Button>
            <Modal isOpen={modalOpen} toggle={toggleModal}>
                <ModalHeader toggle={toggleModal}>Addresses</ModalHeader>
                <ModalBody>
                    <ul>
                        {addressList.map((address, index) => (
                            <li key={index}>
                                {`${address.address}, ${address.city}, ${address.phone}`}
                            </li>
                        ))}
                    </ul>
                    <Row>
                        <Col>
                        <Button color="primary" onClick={toggleForm}>Add New Address</Button>
                        </Col>

                        <Col>
                        <MapModal onAddressSelect={(r) => {
                        setShowForm(!showForm);
                        setNewAddress({
                            address: r,
                            postcode: '',
                            phone: '',
                            city: '',
                            building: '',
                            floor: '',
                        })
                    }}>

                    </MapModal>
                        </Col>
                    </Row>
                    
      
                    {showForm && (
                        <div>
                            <FormGroup>
                                <Label for="address">Address</Label>
                                <Input type="text" id="address" value={newAddress.address} onChange={(e) => setNewAddress({ ...newAddress, address: e.target.value })} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="postcode">Postcode</Label>
                                <Input type="text" id="postcode" value={newAddress.postcode} onChange={(e) => setNewAddress({ ...newAddress, postcode: e.target.value })} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="phone">Phone</Label>
                                <Input type="text" id="phone" value={newAddress.phone} onChange={(e) => setNewAddress({ ...newAddress, phone: e.target.value })} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="city">City</Label>
                                <Input type="text" id="city" value={newAddress.city} onChange={(e) => setNewAddress({ ...newAddress, city: e.target.value })} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="building">Building</Label>
                                <Input type="text" id="building" value={newAddress.building} onChange={(e) => setNewAddress({ ...newAddress, building: e.target.value })} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="floor">Floor</Label>
                                <Input type="text" id="floor" value={newAddress.floor} onChange={(e) => setNewAddress({ ...newAddress, floor: e.target.value })} />
                            </FormGroup>
                        </div>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button disabled={newAddress.address == ""} color="primary" onClick={handleAddAddress}>Add Address</Button>
                    <Button color="success" onClick={handleSave}>Save</Button>
                    <Button color="secondary" onClick={toggleModal}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

AddressModal.propTypes = {
    addresses: PropTypes.arrayOf(PropTypes.shape({
        city: PropTypes.string,
        country: PropTypes.string,
    })),
    onSave: PropTypes.func
};

export default AddressModal;
