import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Alert,
  Spinner,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { getPayments } from "../../helpers/api_helper"

const PaymentOverview = () => {
  // Get ID from the URL
  const { id } = useParams()

  // State to hold payment details
  const [paymentDetails, setPaymentDetails] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    // Fetch payment details based on ID
    const fetchPaymentDetails = async () => {
      try {
        const response = await getPayments(id)
        console.log(response)
        if (response && response[0]) {
          setPaymentDetails(response[0])
        } else {
          setError("Δεν βρέθηκαν λεπτομέρειες πληρωμής.")
        }
      } catch (err) {
        setError("Σφάλμα κατά τη φόρτωση των δεδομένων πληρωμής.")
      } finally {
        setLoading(false)
      }
    }

    fetchPaymentDetails()
  }, [id])

  if (loading) {
    return (
      <Container fluid className="page-content">
        <Spinner color="primary" />
      </Container>
    )
  }

  if (error) {
    return (
      <Container fluid className="page-content">
        <Alert color="danger">{error}</Alert>
      </Container>
    )
  }

  if (!paymentDetails) {
    return null
  }

  // Destructure payment details for display
  const { bound, type, amount, series, date, entity_name, bankname, issuer, expire_date } = paymentDetails

  return (
    <div className="page-content">
      <Container fluid>
        {/* Render Breadcrumbs */}
 

        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <CardTitle className="mb-4">Στοιχεία Πληρωμής</CardTitle>
                
                {/* Display Payment Details */}
                <Row>
                  <Col md={6}>
                    <CardText>
                      <strong>Τύπος Συναλλαγής:</strong> {bound === "Inbound" ? "Εισερχόμενη" : "Εξερχόμενη"}
                    </CardText>
                  </Col>
                  <Col md={6}>
                    <CardText>
                      <strong>Οντότητα:</strong> {entity_name}
                    </CardText>
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <CardText>
                      <strong>Τύπος Πληρωμής:</strong> {type === "CASH" ? "Μετρητά" : type === "CHECK" ? "Επιταγή" : "Κάρτα"}
                    </CardText>
                  </Col>
                  <Col md={6}>
                    <CardText>
                      <strong>Ποσό:</strong> {amount}€
                    </CardText>
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <CardText>
                      <strong>Σειρά:</strong> {series}
                    </CardText>
                  </Col>
                  <Col md={6}>
                    <CardText>
                      <strong>Ημερομηνία:</strong> {new Date(date).toLocaleDateString()}
                    </CardText>
                  </Col>
                </Row>

                {/* If payment type is CHECK, show additional details */}
                {type === "CHECK" && (
                  <>
                    <Row>
                      <Col md={6}>
                        <CardText>
                          <strong>Όνομα Τράπεζας:</strong> {bankname}
                        </CardText>
                      </Col>
                      <Col md={6}>
                        <CardText>
                          <strong>Εκδότης:</strong> {issuer}
                        </CardText>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <CardText>
                          <strong>Ημερομηνία Λήξης:</strong> {new Date(expire_date).toLocaleDateString()}
                        </CardText>
                      </Col>
                    </Row>
                  </>
                )}

              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default PaymentOverview
