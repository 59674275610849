import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import PropTypes from "prop-types";

const CustomerDetails = ({ loans }) => {
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  // Function to handle customer selection
  const handleCustomerSelect = (customerName) => {
    setSelectedCustomer(customerName);
  };

  // Calculate inbound, outbound, and total for the selected customer
  const selectedCustomerData = loans
    .filter((loan) => loan.custname === selectedCustomer)
    .reduce(
      (acc, loan) => {
        const productName = loan.prdname;
        const quantity = loan.quantity;
        const bound = loan.bound;

        if (!acc[productName]) {
          acc[productName] = { inbound: 0, outbound: 0 };
        }

        if (bound === "INBOUND") {
          acc[productName].inbound += quantity;
        } else if (bound === "OUTBOUND") {
          acc[productName].outbound += quantity;
        }

        return acc;
      },
      {}
    );

  // Convert product data into chart data
  const chartData = Object.keys(selectedCustomerData).map((productName) => {
    const inbound = selectedCustomerData[productName].inbound;
    const outbound = selectedCustomerData[productName].outbound;
    const total = outbound - inbound;
    return { productName, inbound, outbound, total };
  });

  const chartOptions = {
    chart: {
      id: "bar-chart",
    },
    xaxis: {
      categories: chartData.map((data) => data.productName),
    },
  };

  const chartSeries = [
    {
      name: "Καθαρή Ποσότητα",
      data: chartData.map((data) => data.total),
    },
  ];

  return (
    <div>
      <h3>Προϊόντα ανά Πελάτη - Κάντε κλικ για να δείτε!</h3>
      <ul>
        {Array.from(new Set(loans.map((loan) => loan.custname))).map(
          (customerName, index) => (
            <li
              key={index}
              onClick={() => handleCustomerSelect(customerName)}
              style={{ cursor: "pointer" }}
            >
              {customerName}
            </li>
          )
        )}
      </ul>

      {selectedCustomer && (
        <div>
          <h3>Πελάτης: {selectedCustomer}</h3>
          <div>
            {chartData.map((data, index) => (
              <div key={index}>
                <p>
                  <strong>Προϊόν:</strong> {data.productName} -{" "}
                  <strong>ΕΙΣΕΡΧΟΜΕΝΟ:</strong> {data.inbound} -{" "}
                  <strong>ΕΞΕΡΧΟΜΕΝΟ:</strong> {data.outbound} -{" "}
                  <strong>ΣΥΝΟΛΟ:</strong> {data.total}
                </p>
              </div>
            ))}
            <ReactApexChart
              options={chartOptions}
              series={chartSeries}
              type="bar"
              height="400"
            />
          </div>
        </div>
      )}
    </div>
  );
};

CustomerDetails.propTypes = {
  loans: PropTypes.array,
};

export default CustomerDetails;
