import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import {
    Table,
    Input,
    Button

} from "reactstrap"
import "react-datepicker/dist/react-datepicker.css"



const InvoiceTable = (props) => {
    const { products } = props;

    return (
        <>
            <Table className="align-middle mb-0">
                <thead>
                    <tr>
                        <th>ITEM</th>
                        <th>NAME</th>
                        <th>UNIT</th>
                        <th>OldPrice</th>
                        <th>PRICE</th>
                        <th>QUANTITY</th>
                        <th>Stock After</th>
                        <th>total</th>
                        <th>Vat</th>
                        <th>DELETE</th>
                    </tr>
                </thead>
                <tbody>
                    {props.products && props.products.map((prds, i) => (
                        <tr key={i}>
                            <th scope="row">{i + 1}</th>
                            <td>{prds.name}</td>
                            <td>{prds.unit}</td>
                            {/* <td style={{ color: prds.costprice > prds.salesprice ? "red" : "green" }}>{prds.costprice}</td> */}
                            <td>
                                {Number(prds.costprice).toFixed(2)}
                            </td>
                            <td>
                                <Input
                                    value={prds.costpricenew || 0}
                                    type="number"
                                    style={{ maxWidth: 100 }}
                                    onChange={(e) => {
                                       // let value = parseInt(e.target.value)
                                       let value = parseInt(prds.quantity)
                                       let stock = parseInt(prds.stock) > 0 ? parseInt(prds.stock) : 0
                                        let newArr = [...products];
                                        newArr[i].costpricenew = e.target.value;
                                        newArr[i].stock = stock;
                                        newArr[i].totalPrdAmmount = parseInt(value * prds.costpricenew);
                                        newArr[i].vatProducts = parseInt((value * prds.costpricenew) * (prds.vat / 100), 10);
                                        props.setproducts(newArr);
                                    }}>
                                </Input>
                            </td>
                            <td>
                                <Input style={{ maxWidth: 100 }} type="number"
                                    defaultValue={0}
                                    onChange={(e) => {
                                        let value = parseInt(e.target.value)
                                        let stock = parseInt(prds.stock) > 0 ? parseInt(prds.stock) : 0
                                        let newArr = [...products];
                                        newArr[i].quantity = value;
                                        newArr[i].stock = stock;
                                        newArr[i].totalPrdAmmount = Number(value * prds.costpricenew).toFixed(2);
                                        newArr[i].vatProducts = Number((value * prds.costpricenew) * (prds.vat / 100), 10).toFixed(2);
                                        props.setproducts(newArr);

                                    }}>
                                </Input>
                            </td>
                     
                   
                       
                            <td>
                                {/* {prds.stock > prds.quantity && <h5 style={{ color: "green" }}>{prds.stock + prds.quantity}</h5>} */}
                                {<h5 style={{ color: "red" }}>{ parseInt(prds.stock)  + parseInt(prds.quantity)}</h5>}
                            </td>
                            <td>{prds.totalPrdAmmount}</td>
                            <td>{prds.vatProducts}</td>
                         

                            <td>
                                <button onClick={() => props.setproducts(products.filter((item, ind) => ind !== i))} type="button" className="btn btn-light btn-sm">Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </>
    )
}


InvoiceTable.propTypes = {
    products: PropTypes.array,
    onGetInvoices: PropTypes.func,
    fixCosts: PropTypes.func,
    history: PropTypes.object,
    setproducts: PropTypes.func,
    checkHistory: PropTypes.func

}

export default withRouter(InvoiceTable)
