import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Input } from "reactstrap";


import PropTypes from "prop-types"


const VATModal = ({ValidData}) => {

  const [modal, setModal] = useState(false);
  const [vatNumber, setVatNumber] = useState("");
  const [companyDetails, setCompanyDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [validationResult, setValidationResult] = useState(null);
  const toggle = () => setModal(!modal);
  const handleVatNumberChange = (event) => {
    setVatNumber(event.target.value);
  };



  const handleValidation = async () => {
    try {
      const response = await fetch(`https://vat.erply.com/numbers?vatNumber=${vatNumber}`);
      const data = await response.json();

      if (response.ok) {
        setValidationResult(data);
        console.log(data)
        setCompanyDetails( { name :data.Name, address:data.Address,country: data.CountryCode ,valid:data.Valid,vat:data.vatNumber} );
      } else {
        setValidationResult(null);
        alert('Failed to validate VAT number. Please check your input and try again.');
      }
    } catch (error) {
      console.error('Error validating VAT:', error);
      setValidationResult(null);
      alert('An error occurred while validating VAT number. Please try again later.');
    }
  };


  return (
    <>
      <Button color="primary" onClick={toggle}>
        VAT Validation
      </Button>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Validate VAT</ModalHeader>
        <ModalBody>
          <Input
            type="text"
            placeholder="Enter VAT Number"
            value={vatNumber}
            onChange={handleVatNumberChange}
          />
          {loading ? (
            <p>Loading...</p>
          ) : companyDetails ? (
            <div>
              <p>{companyDetails.name}</p>
              <p>{companyDetails.address}</p>
              <p>{companyDetails.country}</p>
            </div>
          ) : (
            <p>No company details found.</p>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleValidation}>
            Validate
          </Button>{" "}
          <Button color="primary" onClick={() => { 
            ValidData(companyDetails)
            setModal(false)}}>
            Update Company 
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

VATModal.propTypes = {
  ValidData: PropTypes.func,
}

export default VATModal;
