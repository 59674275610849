import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    Table,
    TabPane,
} from "reactstrap";
import classnames from "classnames";
import { isEmpty } from "lodash";
import avatar from "../../assets/images/users/avatar-1.jpg";
//Import Star Ratings
import StarRatings from "react-star-ratings";

//Import Product Images
import { productImages } from "assets/images/product";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

//Import actions
import {
    getProductDetail as onGetProductDetail,
    getProductComments,
    onAddReply as onAddReplyAction,
    onAddComment as onAddCommentAction,
} from "store/actions"

// import Reviews from "./Reviews"

//redux
import { useSelector, useDispatch } from "react-redux"
import { getProducts ,getMaterials} from "helpers/api_helper";

const MaterialDetail = props => {

    //meta title
    document.title = "Product Details | Skote - React Admin & Dashboard Template";

    const dispatch = useDispatch()

    //   const { product, productComments } = useSelector(state => ({
    //     product: state.ecommerce.product,
    //     productComments: state.ecommerce.productComments,
    //   }))

    const {
        match: { params },
    } = props


    const [product, setProduct] = useState()

    useEffect(() => {
        if (params && params.id) {
            getMaterials(params.id).then(res => {
                setProduct(res[0])
            })
        }
    }, [dispatch, params])


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Ecommerce" breadcrumbItem={"Material details"} />
                    {!isEmpty(product) && (
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col xl="6">
                                                <div className="product-detai-imgs">

                                                    <Row>
                                                        <Col lg="12">
                                                            <Card>
                                                                <CardBody>
                                                                    <div className="d-flex">

                                                                        <div className="flex-grow-1 align-self-center">
                                                                            <div className="text-muted">
                                                                                <h5>Name: {product.name}</h5>
                                                                                <h5>Cost Price: {product.costprice}</h5>
                                                                    
                                                                                <p className="mb-0">B2B Price: {product.b2bsalesprice}</p>
                                                                                <p className="mb-0">Cost Price: {product.salesprice}</p>
                                                                                <p className="mb-0">Unit: {product.unit}</p>
                                                                                <p className="mb-0">Vat: {product.vat}%</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>

                                                    </Row>
                                                </div>
                                            </Col>
                                            <Col xl="6">
                                                <div className="product-detai-imgs">

                                                    <Row>
                                                        <Col lg="12">
                                                            {/* {error && error ? <Alert color="danger">{error}</Alert> : null}
                                                            {success ? <Alert color="success">{success}</Alert> : null} */}

                                                            <Card>
                                                                <CardBody>
                                                                    <div className="d-flex">

                                                                        <div className="flex-grow-1 align-self-center">
                                                                            <div className="text-muted">
                                                                                <h5>SKU: {product.sku}</h5>
                                                                                <h5>STOCK: {product.stock}</h5>
                                                                                <p className="mb-0">Production Cost : 2222</p>
                                                                                <p className="mb-0">Production Hours : 2222</p>
                                                                                <p className="mb-0">Sales Price: {product.salesprice}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="ms-3">
                                                                            <img
                                                                                src={avatar}
                                                                                alt=""
                                                                                className="avatar-md rounded-circle img-thumbnail"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>


                                                    </Row>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    )}
       
                </Container>
            </div>
        </React.Fragment>
    )
}

MaterialDetail.propTypes = {
    match: PropTypes.any,
    history: PropTypes.object,
}

export default MaterialDetail
