import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "reactstrap"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import { map } from "lodash"

//redux
import { useSelector, useDispatch } from "react-redux"

import Breadcrumbs from "components/Common/Breadcrumb"
import DatatableTables from "./datatable"
import { getInvoices as onGetInvoices } from "store/actions"
import { getProducts } from "helpers/api_helper"

const ProductsList = props => {
  //meta title
  document.title = "Invoice List | customers - React Admin & Dashboard Template";
  const [products, setProducts] = useState([])


  useEffect(() => {
    getProducts().then(res => setProducts(res))
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        {console.log(products)}
        <DatatableTables products={products}></DatatableTables>
        <Row>
          <Col xs="12">
            <div className="text-center my-3">
            </div>
          </Col>
        </Row>

      </div>
    </React.Fragment>
  )
}

ProductsList.propTypes = {
  invoices: PropTypes.array,
  onGetInvoices: PropTypes.func,
}

export default withRouter(ProductsList)
