import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";

// Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

const SidebarContent = (props) => {
  const ref = useRef();

  useEffect(() => {
    const pathName = props.location.pathname;

    const initMenu = () => {
      new MetisMenu("#side-menu");
      let matchingMenuItem = null;
      const ul = document.getElementById("side-menu");
      const items = ul.getElementsByTagName("a");
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.location.pathname]);

  useEffect(() => {
    ref.current.recalculate();
  });

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); // a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">

            <li className="menu-title">Sales</li>

            <li>
              <Link to="/#" className="">
                <i className="bx bx-box"></i>
                <span>Παραγγελίες</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/orders">Όλες οι Παραγγελίες</Link>
                </li>
                <li>
                  <Link to="/createorder">Δημιουργία Παραγγελίας</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="">
                <i className="bx bx-credit-card"></i>
                <span>Δάνεια</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/Loans">Δάνεια</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="">
                <i className="bx bx-package"></i>
                <span>Παραδόσεις</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/shipping">Αποστολή</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="">
                <i className="bx bx-dollar"></i>
                <span>Πληρωμές</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/createpayment">Δημιουργία Πληρωμής</Link>
                </li>
                <li>
                  <Link to="/payments-list/">Λίστα Πληρωμών</Link>
                </li>
              </ul>
            </li>

            <li className="menu-title">Internal</li>

            <li>
              <Link to="/#" className="">
                <i className="bx bx-gift"></i>
                <span>Προβολές</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>

                  <Link to="/dashboard">Υπόλοιπο Πελατών</Link>
                </li>
                <li>
                  <Link to="/dashboardproviders" className="">Υπόλοιπο Παρόχων</Link>
                </li>
                <li>
                  <Link to="/dashboardproducts">Κορυφαία Προϊόντα</Link>
                </li>
                <li>
                  <Link to="/dashboardmaterials">Κορυφαία Materials</Link>
                </li>

              </ul>
            </li>


            <li>
              <Link to="/#" className="">
                <i className="bx bx-user"></i>
                <span>Πελάτες</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/createcustomer">Δημιουργία Πελάτη</Link>
                </li>
                <li>
                  <Link to="/customers">Πελάτες</Link>
                </li>

              </ul>
            </li>

            <li>
              <Link to="/#" className="">
                <i className="bx bx-building"></i>
                <span>Προμηθευτές</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/createprovider">Δημιουργία Προμηθευτή</Link>
                </li>
                <li>
                  <Link to="/providers">Προμηθευτές</Link>
                </li>

              </ul>
            </li>

            <li>
              <Link to="/#" className="">
                <i className="bx bx-gift"></i>
                <span>Προϊόντα</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/Products">Λίστα Προϊόντων</Link>
                  <Link to="/createproduct">Νέο Προϊόν</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="">
                <i className="bx bx-file"></i>
                <span>Τιμολόγια</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/stock-material">Προσθήκη Υλικού Τιμολογίου</Link>
                </li>
                <li>
                  <Link to="/invoices">Λίστα Τιμολογίων</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="">
                <i className="bx bx-cog"></i>
                <span>Παραγωγή</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/tasks">Εργασίες Overview</Link>
                </li>
                <li>
                  <Link to="/section">Τμήμαta</Link>
                </li>
        
              </ul>
            </li>
            <li>
              <Link to="/#" className="">
                <i className="bx bx-cog"></i>
                <span>System Settings</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/settings">General</Link>
                </li>
      
           
                <li>
                  <Link to="/tasktypes">Sections Setup</Link>
                </li>
              </ul>
            </li>

          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
};

export default withRouter(SidebarContent);
