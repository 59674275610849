import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Input,
  FormGroup,
  Label,
} from "reactstrap"
import PropTypes from "prop-types"

// Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

import CustomerSelect from "pages/Orders/customersmodal"
// Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { postPayment, getCustomers, getProviders } from "../../helpers/api_helper"

const PaymentCreate = (props) => {
  document.title = "Δημιουργία Πληρωμής | kerino - React Admin & Dashboard Template"

  const [bound, setBound] = useState("Inbound") // Default to Inbound
  const [entity, setEntity] = useState("") // For customer/provider
  const [type, setType] = useState("") // For payment type
  const [amount, setAmount] = useState(0)
  const [series, setSeries] = useState("")
  const [date, setDate] = useState(new Date())
  const [entityList, setEntityList] = useState([]) // To hold customers or providers
  const [entityName, setEntityName] = useState("") // To store selected entity name
  const [entityId, setEntityId] = useState("") // To store selected entity id

  // New states for CHECK specific fields
  const [bankName, setBankName] = useState("") // For Bank Name
  const [expireDate, setExpireDate] = useState(null) // For Date of Expiry
  const [issuer, setIssuer] = useState("") // For Issuer

  useEffect(() => {
    if (bound === "Inbound") {
      getCustomers().then(res => {
        setEntityList(res) // Assuming you receive customers from the API
      })
    } else if (bound === "Outbound") {
      getProviders().then(res => {
        setEntityList(res) // Assuming you receive providers from the API
      })
    }
  }, [bound])

  const createPayment = () => {
    const payload = {
      resource: [
        {
          bound,
          type,
          ammount: amount,
          series,
          date,
          entity_id: entity,
          entity_name: entityName,
          ...(type === "CHECK" && { bankname: bankName, expire_date: expireDate, issuer }), // Include these fields if type is CHECK
        },
      ],
    }

    postPayment(payload).then(res => {
      if (res && res.error) {
        // handle error
      }
      if (res && res.resource[0]) {
        props.history.push("/payment-overview/" + res.resource[0].id)
      }
    })
  }

  return (
    <>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Πληρωμές" breadcrumbItem={entityName} />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
      
                  <FormGroup>
                    <Label>Τύπος Συναλλαγής</Label>
                    <Row>
                      <Col>
                        <Button
                          color={bound === "Inbound" ? "success" : "secondary"}
                          onClick={() => setBound("Inbound")}
                          className="w-100"
                        >
                          Εισερχόμενη
                        </Button>
                      </Col>
                      <Col>
                        <Button
                          color={bound === "Outbound" ? "success" : "secondary"}
                          onClick={() => setBound("Outbound")}
                          className="w-100"
                        >
                          Εξερχόμενη
                        </Button>
                      </Col>
                    </Row>
                  </FormGroup>

                  {bound && (
                    <FormGroup style={{marginTop:20}}>

                      <Label style={{marginRight:50}} for="entity">
                        {bound === "Inbound" ? "Επιλογή Πελάτη" : "Επιλογή Προμηθευτή"}
                      </Label>

                      <CustomerSelect customers={entityList} onSelect={(e) => {
                        const selectedOption = entityList.find(
                          (item) => item.id === e
                        )
                        setEntityId(selectedOption.id)
                        setEntityName(selectedOption.name)
                        setEntity(e)
                      }} />
                    </FormGroup>
                  )}

                  {entity && (
                    <FormGroup>
                      <Label for="paymentType">Τύπος Πληρωμής</Label>
                      <Row>
                        <Col>
                          <Button
                            color={type === "CASH" ? "success" : "secondary"}
                            onClick={() => setType("CASH")}
                            className="w-100"
                          >
                            <i className="bx bx-money bx-sm"></i> Μετρητά
                          </Button>
                        </Col>
                        <Col>
                          <Button
                            color={type === "CHECK" ? "success" : "secondary"}
                            onClick={() => setType("CHECK")}
                            className="w-100"
                          >
                            <i className="bx bx-check-square bx-sm"></i> Επιταγή
                          </Button>
                        </Col>
                        <Col>
                          <Button
                            color={type === "CARD" ? "success" : "secondary"}
                            onClick={() => setType("CARD")}
                            className="w-100"
                          >
                            <i className="bx bx-credit-card bx-sm"></i> Κάρτα
                          </Button>
                        </Col>
                      </Row>
                    </FormGroup>
                  )}

                  {type && (
                    <>
                      {/* Split the Amount and Series into two columns */}
                      <Row>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="amount">Ποσό</Label>
                            <Input
                              type="number"
                              id="amount"
                              value={amount}
                              onChange={(e) => setAmount(e.target.value)}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="series">Σειρά</Label>
                            <Input
                              type="text"
                              id="series"
                              value={series}
                              onChange={(e) => setSeries(e.target.value)}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      {/* Split the Date and Expire Date into two columns */}
                      <Row>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="date">Ημερομηνία</Label>
                            <DatePicker
                              id="date"
                              selected={date}
                              onChange={(date) => setDate(date)}
                              className="form-control"
                            />
                          </FormGroup>
                        </Col>
                        {type === "CHECK" && (
                          <Col md={6}>
                            <FormGroup>
                              <Label for="expireDate">Ημερομηνία Λήξης</Label>
                              <DatePicker
                                id="expireDate"
                                selected={expireDate}
                                onChange={(date) => setExpireDate(date)}
                                className="form-control"
                              />
                            </FormGroup>
                          </Col>
                        )}
                      </Row>

                      {/* Conditionally render CHECK specific fields */}
                      {type === "CHECK" && (
                        <>
                          <Row>
                            <Col md={6}>
                              <FormGroup>
                                <Label for="bankName">Όνομα Τράπεζας</Label>
                                <Input
                                  type="text"
                                  id="bankName"
                                  value={bankName}
                                  onChange={(e) => setBankName(e.target.value)}
                                />
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup>
                                <Label for="issuer">Εκδότης</Label>
                                <Input
                                  type="text"
                                  id="issuer"
                                  value={issuer}
                                  onChange={(e) => setIssuer(e.target.value)}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </>
                      )}

                      <Button color="primary" onClick={createPayment}>
                        Δημιουργία Πληρωμής
                      </Button>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

PaymentCreate.propTypes = {
  history: PropTypes.object,
}

export default PaymentCreate
