import React from 'react';
import { renderToString } from 'react-dom/server';
import InvoiceTemplate from './printInvoiceTemplate';

export function printInvoice(order) {
  try {
    const invoiceHTML = renderToString(<InvoiceTemplate order={order} />);

    // Create an iframe element
    const iframe = document.createElement('iframe');
    iframe.style.position = 'absolute';
    iframe.style.width = '0px';
    iframe.style.height = '0px';
    iframe.style.border = 'none';

    // Append the iframe to the body
    document.body.appendChild(iframe);

    // Write the invoice HTML content to the iframe
    const doc = iframe.contentDocument || iframe.contentWindow.document;
    doc.open();
    doc.write(`
      <html>
        <head>
          <title>Invoice</title>
          <style>
            @media print {
              body { font-family: Arial, sans-serif; padding: 20px; }
              .invoice-table { width: 100%; border-collapse: collapse; }
              .invoice-table th, .invoice-table td { border: 1px solid black; padding: 10px; }
            }
            body { font-family: Arial, sans-serif; padding: 20px; }
          </style>
        </head>
        <body>
          ${invoiceHTML}
        </body>
      </html>
    `);
    doc.close();

    // Print the content of the iframe
    iframe.contentWindow.focus();
    iframe.contentWindow.print();

    // Clean up: remove the iframe from the document
    document.body.removeChild(iframe);

  } catch (error) {
    console.error('Error printing invoice:', error);
  }
}
