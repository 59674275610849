import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { map } from "lodash";

// redux
import { useSelector, useDispatch } from "react-redux";

// Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

// Import Card invoice
import CardInvoice from "./card-invoice";
import { getInvoices as onGetInvoices } from "store/actions";
import OrdersTable from "./ordersTable";
import ShippingTable from "./shippingtable";
import { getOrdersbystatus } from "helpers/api_helper";

const ShippingList = (props) => {
  // meta title
  const [filteredOrders, setFilteredOrders] = useState([]);

  useEffect(() => {
    getOrdersbystatus("INVOICED").then((res) => setFilteredOrders(res));
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Πελάτες" breadcrumbItem="Shipping" />
          <ShippingTable
            onUpdateSelectedOrders={() => {
              setFilteredOrders([]);
              getOrdersbystatus("INVOICED").then((res) => setFilteredOrders(res));
            }}
            orders={filteredOrders}
          ></ShippingTable>
        </Container>
      </div>
    </React.Fragment>
  );
};

ShippingList.propTypes = {
  invoices: PropTypes.array,
  onGetInvoices: PropTypes.func,
};

export default withRouter(ShippingList);
