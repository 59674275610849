import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types'; // Import PropTypes for prop validation
import { Modal, ModalHeader, ModalBody, Row, Col, Button, ListGroup, ListGroupItem, Card, CardBody, CardTitle, Input } from 'reactstrap';
import CustomerSelect from 'pages/Orders/customersmodal';
import { postTask, postLoan } from 'helpers/api_helper';

const LoanModal = ({ fetchLoansData, isOpen, customers, setLoanModal, setCustomerId, loans }) => {

  const [customerid, setCustomerid] = useState('');
  const [selectedCustomerLoans, setSelectedCustomerLoans] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [returnedQuantity, setReturnedQuantity] = useState('');

  useEffect(() => {
    if (isOpen) {
      setSelectedProduct(null);
      setReturnedQuantity('');
      setCustomerid('');
      setSelectedCustomerLoans([]);
    }
  }, [isOpen]);

  const createTask = async (selectedProduct, returnedQuantity, customerid) => {
    const payload = {
      "resource": [
        {
          "type": "ΠΛΥΝΤΗΡΙΟ",
          "materials": "RETURN",
          "status": "PENDING",
          "orderref": selectedProduct.order_id,
          "productid": selectedProduct.prd_id,
          "name": selectedProduct.prdname,
          "description": "EPISTROFH-PLYSIMO",
          "quantity": parseInt(returnedQuantity),
          "customer_id_ref": customerid,
          "customer_name": selectedProduct.customername,
          "seq": 1
        }
      ],
    }
    postTask(payload).then(res => {
      if (res && res.error) {
        console.log(res)
      }
      if (res && res.resource) {
        const Loanpayload = {
          "resource": [
            {
              "bound": "INBOUND",
              "quantity": returnedQuantity,
              "customer_id": customerid,
              "order_id": selectedProduct.order_id,
              "prd_id": selectedProduct.prd_id,
              "prdname": selectedProduct.prdname,
              "custname": selectedProduct.custname
            }
          ],
        };

        const loanpost = postLoan(Loanpayload)
        fetchLoansData()
        setLoanModal(false)
      }
    })
  }

  const handleCustomerSelect = (selectedCustomer) => {
    console.log(selectedCustomer)
    // Ensure selectedCustomer is valid
    if (!selectedCustomer) {
      console.error('Selected customer is null or undefined');
      return;
    }
  
    setCustomerid(selectedCustomer);
    setSelectedProduct(null);
    setReturnedQuantity('');
  
    // Log initial data
    console.log('Selected Customer:', selectedCustomer);
    console.log('Loans:', loans);
  
    // Filter and process loans related to the selected customer
    const filteredLoans = loans
      .filter((loan) => loan.customer_id === selectedCustomer)
      .reduce((acc, loan) => {
        const { order_id, prdname, prd_id, quantity, custname, bound, customer_id } = loan;
  
        // Log each step of processing
        console.log('Processing loan:', loan);
  
        const existingProduct = acc.find((item) => item.prd_id === prd_id);
  
        if (existingProduct) {
          if (bound === 'OUTBOUND') {
            existingProduct.outbound += quantity;
          } else if (bound === 'INBOUND') {
            existingProduct.inbound += quantity;
          }
          existingProduct.total = existingProduct.outbound - existingProduct.inbound;
        } else {
          acc.push({
            customer_id,
            order_id,
            prdname,
            custname,
            prd_id,
            inbound: bound === 'INBOUND' ? quantity : 0,
            outbound: bound === 'OUTBOUND' ? quantity : 0,
            total: bound === 'OUTBOUND' ? quantity : -quantity,
          });
        }
  
        return acc;
      }, []);

    setSelectedCustomerLoans(filteredLoans)
  };

  // Function to handle product selection
  const handleProductSelect = (product) => {
    setSelectedProduct(product);
    setReturnedQuantity(''); // Reset the quantity input when selecting a new product
  };

  // Function to handle input change for returned quantity
  const handleQuantityChange = (e) => {
    const quantity = e.target.value;
    setReturnedQuantity(quantity);
  };

  // Function to create the return when the button is clicked
  const handleCreateReturn = () => {
    if (!selectedProduct || !returnedQuantity) {
      // If no product is selected or no quantity is entered, return early
      return;
    } else {
      console.log(selectedProduct)
      console.log(returnedQuantity)
      createTask(selectedProduct, returnedQuantity, customerid)
    }
  };

  const canCreateReturn = selectedProduct && returnedQuantity > 0; // Check if a product is selected and a valid quantity is entered

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader tag="h4">
        <Row style={{ marginLeft: 5 }}>
          <Col>Δημιουργία Τιμολογίου Επιστροφής Εισερχόμενου</Col>
        </Row>
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col>Αριθμός Παραγγελίας</Col>
          <CustomerSelect customers={customers} onSelect={handleCustomerSelect} />
        </Row>
        {customerid && ( // Conditionally render the selected customer name
          <Row>
            <Col>Επιλεγμένος Πελάτης: {customerid}</Col>
          </Row>
        )}
        {selectedCustomerLoans.length > 0 && ( // Conditionally render the loans list
          <Row>
            <Col>
              <h5>Δανεισμένα Στοιχεία Πελάτη</h5>
              <ListGroup>
                {selectedCustomerLoans.map((loan) => (
                  <ListGroupItem key={loan.prd_id} onClick={() => handleProductSelect(loan)}>
                    <Row>{loan.prdname} Δανεισμένα: {loan.total}</Row>
                    <Row>
                      ΣΥΝΟΛΟ ΕΙΣΕΡΧΟΜΕΝΟ: {loan.inbound} ΣΥΝΟΛΟ ΕΞΕΡΧΟΜΕΝΟ: {loan.outbound}
                    </Row>
                  </ListGroupItem>
                ))}
              </ListGroup>
            </Col>
          </Row>
        )}
        {selectedProduct && ( // Show a card when a product is selected
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <CardTitle tag="h5">{selectedProduct.prdname}</CardTitle>
                  <Input
                    type="number"
                    placeholder="Εισάγετε ποσότητα επιστροφής"
                    value={returnedQuantity}
                    onChange={handleQuantityChange}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
        {canCreateReturn && ( // Show the "Create Return" button when conditions are met
          <Row>
            <Col>
              <Button color="primary" onClick={handleCreateReturn}>Δημιουργία Επιστροφής</Button>
            </Col>
          </Row>
        )}
      </ModalBody>
      <Button onClick={() => setLoanModal(false)}>Κλείσιμο</Button>
    </Modal>
  );
};

// Define prop types for LoanModal
LoanModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  customers: PropTypes.array,
  setLoanModal: PropTypes.func.isRequired,
  setCustomerId: PropTypes.func.isRequired,
  loans: PropTypes.array.isRequired,
  fetchLoansData: PropTypes.func
};

export default LoanModal;
