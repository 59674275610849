import React, { useEffect,useState } from "react"
import { Col, Container, Row } from "reactstrap"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import { map } from "lodash"
import ProvidersTable from "./providers_table"

//redux
import { useSelector, useDispatch } from "react-redux"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//Import Card invoice
import CardCustomer from "./card-customer"
import { getCustomers as onGetCustomers } from "store/actions"
import { getProviders } from "helpers/api_helper"
const ProviderList = props => {
   //meta title
   //console.log(props)
   document.title="Custoemers List | customers - React Admin & Dashboard Template";

   const [providers, setproviders] = useState([])

   useEffect(() => {
getProviders().then(res =>setproviders(res));

  }, []);


console.log(providers)
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="customers" breadcrumbItem="Providers List" />
          <Row>
       
              <ProvidersTable data={providers} />

          </Row>
          <Row>
            <Col xs="12">
              <div className="text-center my-3">
                {/* <Link to="#" className="text-success">
                  <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                  Load more
                </Link> */}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ProviderList.propTypes = {
  customers: PropTypes.array,
  onGetInvoices: PropTypes.func,
}

export default withRouter(ProviderList)
