import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,

} from "reactstrap";
import { options, series, statusClasses } from "common/data/tasks"
import classNames from "classnames"
import { isEmpty, map, size } from "lodash"

import PropTypes from "prop-types"

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

import avatar from "../../assets/images/users/avatar-1.jpg";
// actions
import { editProfile, resetProfileFlag } from "../../store/actions";

const InvoiceDetail = (props) => {

  //meta title
  document.title = "Profile | kerino - React Admin & Dashboard Template";

  const dispatch = useDispatch();

  const [email, setemail] = useState("");
  const [name, setname] = useState("");
  const [idx, setidx] = useState(1);
  const [products, setproducts] = useState([]);
  const { error, success } = useSelector(state => ({
    error: state.Profile.error,
    success: state.Profile.success,
  }));
  useEffect(() => {
    setproducts(JSON.parse(props.location.state.products))
  }, [])


  //console.log(products)
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="kerino" breadcrumbItem="ORDER ANALYSIS" />

          <Row>
            <Col lg="12">
              {error && error ? <Alert color="danger">{error}</Alert> : null}
              {success ? <Alert color="success">{success}</Alert> : null}

              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="ms-3">
                      <img
                        src={avatar}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <h5>{name}</h5>
                        <p className="mb-1">{email}</p>
                        <p className="mb-0">Id no: #{idx}</p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>


          <Card>
            <CardBody>
              <Label className="form-label">Order Items</Label>

              <table className="table table-nowrap align-middle mb-0">
                <tbody>
                  {map(products, (item, i) => (
                    <tr key={i}>
                      <td style={{ width: "40px" }}>
                        <div className="form-check font-size-16">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id={item.id}
                          />
                        </div>
                      </td>
                      <td>
                        <h5 className="text-truncate font-size-14 m-0">
                          {item.name}
                        </h5>
                      </td>
                      <td>
                        <h5 className="text-truncate font-size-14 m-0">
                          {item.quantity}
                        </h5>
                      </td>
                      <td>

                        <h5 className="text-truncate font-size-14 m-0">
                          {item.vatProductTotal}
                        </h5>
                      </td>
                      <td>
                        <div className="team">

                          {/* {size(item.id > 2)  && (
                            <Link
                              to="#"
                              className="d-inline-block me-1"
                            >
                              <div className="avatar-xs">
                                <span className="avatar-title rounded-circle bg-warning text-white font-size-16">
                                  {size(item.title) - 2} +
                                </span>
                              </div>
                            </Link>
                          )} */}
                        </div>
                      </td>
                      <td>
                        <div className="text-center">
                          {/* <span
                            className={classNames(
                              "badge badge-pill font-size-11",
                              statusClasses[item.status.toLowerCase()]
                            )}
                          >
                            {item.status}
                          </span> */}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

InvoiceDetail.propTypes = {

  location: PropTypes.object,
}

export default withRouter(InvoiceDetail);
