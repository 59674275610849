import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Input,
  FormGroup,
  Label,
  Button,
} from "reactstrap"
import ReactDrawer from 'react-drawer';
import PropTypes from "prop-types"

// Import Editor
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { postMaterial, getTypes, getMaterials } from "../../helpers/api_helper"


const MaterialsCreate = (props) => {
  document.title = "Create Material | kerino";


  const [name, setname] = useState()
  const [materialsList, setMaterialsList] = useState([])
  const [open, setOpen] = useState(false);
  const [sku, setsku] = useState()
  const [vat, setvat] = useState()
  const [description, setdescription] = useState()
  const [b2bsalesprice, setb2bsalesprice] = useState()
  const [costprice, setcostprice] = useState()
  const [vatClass, setvatClass] = useState()
  const [stock, setstock] = useState()
  const [unit, setunit] = useState()
  const [types, settypes] = useState([])
  const [steps, setstep] = useState([])
  const [selectedStep, setSelectedStep] = useState([])
  const [type, settype] = useState("GEMISMA")
  //console.log(steps)


  useEffect(() => {
    getTypes().then(res => settypes(res))
    getMaterials().then(res => setMaterialsList(res))
  }, [])

  //meta title
  const createMaterial = () => {
    const payload = {
      "resource": [
        {
          "name": name,
          "description": description,
          "vat": vat,
          "costprice": costprice,
          "vatClass": vatClass,
          "stock": stock,
          "unit": unit,

        }
      ],
    }

    postMaterial(payload).then(res => {
      if (res && res.error) {
        //console.log(res)
      }
      if (res && res.resource[0]) {
        props.history.push("/materials-list")
      }
    })

  }
  return (
    <>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Tasks" breadcrumbItem="Create Material" />

          <Row>
            <Col lg="6">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Create New Product</CardTitle>
                  <form className="outer-repeater">
                    <div data-repeater-list="outer-group" className="outer">
                      <div data-repeater-item className="outer">
                        <FormGroup className="mb-4" row>
                          <Label
                            htmlFor="taskname"
                            className="col-form-label col-lg-2"
                          >
                            Name
                          </Label>
                          <Col lg="10">
                            <Input
                              id="taskname"
                              name="taskname"
                              type="text"
                              className="form-control"
                              value={name}
                              onChange={(e) => setname(e.target.value)}
                              placeholder="Enter Task Name..."
                            />
                          </Col>
                        </FormGroup>


                        <FormGroup className="mb-4" row>
                          <Label
                            htmlFor="taskname"
                            className="col-form-label col-lg-2"
                          >
                            Description
                          </Label>
                          <Col lg="10">
                            <Input
                              id="taskname"
                              name="taskname"
                              type="area"
                              className="form-control"
                              value={description}
                              onChange={(e) => setdescription(e.target.value)}
                              placeholder="Enter Task Name..."
                            />
                          </Col>
                        </FormGroup>


                        <FormGroup className="mb-4" row>
                          <label
                            htmlFor="taskbudget"
                            className="col-form-label col-lg-2"
                          >
                            Vat
                          </label>
                          <div className="col-lg-10">
                            <Input
                              id="taskbudget"
                              name="taskbudget"
                              type="text"
                              onChange={(e) => setvat(e.target.value)}
                              placeholder="Enter Task Budget..."
                              className="form-control"
                            />
                          </div>
                        </FormGroup>

               
                        <FormGroup className="mb-4" row>
                          <label
                            htmlFor="taskbudget"
                            className="col-form-label col-lg-2"
                          >
                            Cost price
                          </label>
                          <div className="col-lg-10">
                            <Input
                              id="taskbudget"
                              name="taskbudget"
                              type="text"
                              value={costprice}
                              onChange={(e) => setcostprice(e.target.value)}
                              placeholder="Enter Task Budget..."
                              className="form-control"
                            />
                          </div>
                        </FormGroup>
               

                        <FormGroup className="mb-4" row>
                          <label
                            htmlFor="taskbudget"
                            className="col-form-label col-lg-2"
                          >
                            Stock
                          </label>
                          <div className="col-lg-10">
                            <Input
                              id="taskbudget"
                              name="taskbudget"
                              type="number"
                              value={stock}
                              onChange={(e) => setstock(e.target.value)}
                              placeholder="Enter Stock"
                              className="form-control"
                            />
                          </div>
                        </FormGroup>


                        <FormGroup className="mb-4" row>
                          <label
                            htmlFor="taskbudget"
                            className="col-form-label col-lg-2"
                          >
                            Unit
                          </label>
                          <div className="col-lg-10">
                          <Input     value={unit} onChange={(e) => setunit(e.target.value)} type="select" name="select" id="exampleSelect">
                            <option value={"KG"}>Killos</option> 
                            <option value={"Meter"}>Meter</option> 
                            <option value={"CM"}>Centimeters</option> 
                            <option value={"MM"}>Milimmetes</option> 
                            <option value={"Litter"}>Litter</option> 
                            <option value={"CubicM"}>CubicM</option>
                            <option value={"Item"}>Item</option>
                            </Input>
                          </div>
                        </FormGroup>
                      </div>
                    </div>
                  </form>
                  
                  <Row className="justify-content-end">
                    <Col lg="10">
                      <Button onClick={() => {
                        createMaterial()
                      }} type="submit" color="primary">
                        Create Material
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col>
             
            </Col>
          </Row>

        </Container>

      </div>
    </>
  )
}
MaterialsCreate.propTypes = {
  history: PropTypes.object,
}

export default MaterialsCreate
