import React, { useEffect ,useState} from "react"
import { Col, Container, Row } from "reactstrap"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import { map } from "lodash"
import { useSelector, useDispatch } from "react-redux"
import Breadcrumbs from "components/Common/Breadcrumb"
import DatatableTables from "./datatable"
import { getMaterials } from "helpers/api_helper"

const MaterialsList = props => {
   document.title="Invoice List | customers - React Admin & Dashboard Template";
   
   const [materials, setMaterials] = useState([])


  useEffect(() => {
  getMaterials().then((res) => setMaterials(res))
  }, [])
//console.log(materials)
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="customers" breadcrumbItem="Materials List" />
          <Row>
          <DatatableTables  products={materials}></DatatableTables>
          </Row>
          <Row>
            <Col xs="12">
              <div className="text-center my-3">
  
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

MaterialsList.propTypes = {
  invoices: PropTypes.array,
  onGetInvoices: PropTypes.func,
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
    ...state
  }
}


export default withRouter(MaterialsList)
