import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    Table,
    TabPane,
} from "reactstrap";
import classnames from "classnames";
import { isEmpty } from "lodash";
import avatar from "../../assets/images/users/avatar-1.jpg";
//Import Star Ratings
import StarRatings from "react-star-ratings";

//Import Product Images
import { productImages } from "assets/images/product";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

//Import actions
import {
    getProductDetail as onGetProductDetail,
    getProductComments,
    onAddReply as onAddReplyAction,
    onAddComment as onAddCommentAction,
} from "store/actions"

// import Reviews from "./Reviews"

//redux
import { useSelector, useDispatch } from "react-redux"
import { getProducts } from "helpers/api_helper";

const ProductDetail = props => {
    document.title = "Product Details | Skote - React Admin & Dashboard Template";

    const dispatch = useDispatch()

    const {
        match: { params },
    } = props

    const [activeTab, setActiveTab] = useState("1")
    const [product, setProduct] = useState()
    const [requirements, setrequirements] = useState([])

    useEffect(() => {
        if (params && params.id) {
            getProducts(params.id).then(res => {
                setProduct(res[0])
                setrequirements(JSON.parse(res[0].requirements))
            })
        }
    }, [dispatch, params])

    const toggleTab = tab => {
        if (activeTab !== tab) {
            setActiveTab(tab)
        }
    }

    const imageShow = (img, id) => {
        const expandImg = document.getElementById("expandedImg" + id)
        expandImg.src = img
    }

console.log(product)
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Ecommerce" breadcrumbItem={"Product details "} />
                    {!isEmpty(product) && (
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col xl="6">
                                                <div className="product-detai-imgs">

                                                    <Row>
                                                        <Col lg="12">
                                                            {/* {error && error ? <Alert color="danger">{error}</Alert> : null}
                                                            {success ? <Alert color="success">{success}</Alert> : null} */}

                                                            <Card>
                                                                <CardBody>
                                                                    <div className="d-flex">

                                                                        <div className="flex-grow-1 align-self-center">
                                                                            <div className="text-muted">
                                                                                <h5>Name: {product.name}</h5>
                                                                                <h5>Sales Price: {product.salesprice}</h5>
                                                                                <p className="mb-0">B2B Price: {product.b2bsalesprice}</p>
                                                                                <p className="mb-0">Sales Price: {product.salesprice}</p>
                                                                                <p className="mb-0">Unit: {product.unit}</p>
                                                                                <p className="mb-0">Vat: {product.vat}%</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>

                                                        <Button onClick={() => props.history.push('/product-update',{product})}>Edit Product</Button>
                                                    </Row>
                                                </div>
                                            </Col>
                                            <Col xl="6">
                                                <div className="product-detai-imgs">

                                                    <Row>
                                                        <Col lg="12">
                                                            {/* {error && error ? <Alert color="danger">{error}</Alert> : null}
                                                            {success ? <Alert color="success">{success}</Alert> : null} */}

                                                            <Card>
                                                                <CardBody>
                                                                    <div className="d-flex">

                                                                        <div className="flex-grow-1 align-self-center">
                                                                            <div className="text-muted">
                                                                                <h5>SKU: {product.sku}</h5>
                                                                                <h5>STOCK: {product.stock}</h5>
                                                                                <p className="mb-0">Production Cost : 2222</p>
                                                                                <p className="mb-0">Production Hours : 2222</p>
                                                                                <p className="mb-0">Sales Price: {product.salesprice}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="ms-3">
                                                                            <img
                                                                                src={avatar}
                                                                                alt=""
                                                                                className="avatar-md rounded-circle img-thumbnail"
                                                                            />
                                                                        </div>

                                                                    </div>
                                                                </CardBody>
                                                                {product && product.productPhotos && JSON.parse(product.productPhotos).map((img, i) => (
      <div key={i} style={{ position: 'relative', display: 'inline-block', margin: 10 }}>
        <img src={img} alt="webcam preview" style={{ width: '150px', height: '150px' }} />
      </div>
    ))}
                                                            </Card>
                                                        </Col>


                                                    </Row>
                                                </div>
                                            </Col>


                                        </Row>

                                        <div className="mt-5">
                                            <h5 className="mb-3">Production tasks:</h5>
                                            <div className="table-responsive">
                                                {requirements &&
                                                    requirements.map((regs, i) => (
                                                        <div style={{ margin: 15 }} key={i}>
                                                            <h3>{regs.typid}</h3>
                                                            {regs.materials && regs.materials.map((mat, i) => (
                                                                <div key={i}>
                                                                    <h5>{mat.name}-{mat.description}  Quantity:{mat.quantity}{mat.unit}-Cost:{mat.costprice}</h5>
                                                                </div>))}
                                                        </div>
                                                    ))}


                                            </div>
                                        </div>

                                        {/* <Reviews
                      comments={[...comments]}
                      productId={params.id || 1}
                      onClickReply={onClickReply}
                      onCancelReply={onCancelReply}
                      onAddReply={onAddReply}
                      onAddComment={onAddComment}
                    /> */}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    )}
                    {/* <RecentProducts recentProducts={product.recentProducts} /> */}
                </Container>
            </div>
        </React.Fragment>
    )
}

ProductDetail.propTypes = {
    product: PropTypes.object,
    match: PropTypes.any,
    onGetProductDetail: PropTypes.func,
    history: PropTypes.object,
}

export default ProductDetail
