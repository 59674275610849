import {
  GET_CUSTOMERS,
  GET_CUSTOMERS_FAIL,
  GET_CUSTOMERS_SUCCESS,
  GET_CUSTOMER_DETAIL,
  GET_CUSTOMER_DETAIL_FAIL,
  GET_CUSTOMER_DETAIL_SUCCESS,
} from "./actionTypes"

export const getCustomers = () => ({
  type: GET_CUSTOMERS,
})

export const getCustomersSuccess = invoices => ({
  type: GET_CUSTOMERS_SUCCESS,
  payload: invoices,
})

export const getCustomersFail = error => ({
  type: GET_CUSTOMERS_FAIL,
  payload: error,
})

export const getCustomersDetail = invoiceId => ({
  type: GET_CUSTOMER_DETAIL,
  invoiceId,
})

export const getCustomerDetailSuccess = invoices => ({
  type: GET_CUSTOMER_DETAIL_SUCCESS,
  payload: invoices,
})

export const getCustomerDetailFail = error => ({
  type: GET_CUSTOMER_DETAIL_FAIL,
  payload: error,
})
